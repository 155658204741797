import React, { useContext, useEffect, useState } from "react";
import { useParams, Outlet, useLocation } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import { AppContext } from "../../lib/AppProvider";
import { AuthContext } from "../../lib/Auth";
import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, styled } from "@mui/material";
import Grid from '@mui/material/Grid2';
import {BorderLinearProgress} from '../common';

export const AdminPageContext = React.createContext();

export const AdminPageProvider = ({children}) => {
    const location = useLocation();
    const isAdminPath = location.pathname.startsWith('/admin');
    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(AppContext);
    const [loading, setLoading] = React.useState(true);
    const [ admin, setAdmin ] = useState(null);

    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);

    useEffect(() => {
        const getAdmin = async () => {
            const adminDoc = doc(firestoreInstance, 'systemAdmin', authUser.user.uid);
            await getDoc(adminDoc).then(docSnap => {
                const data = docSnap.data();
                //console.log("Document data:", data);
                if (data != 'undefined') {
                    setAdmin(data.permission);
                }
                setLoading(false);
              }).catch(err => {
                //console.log('err', err);
                setLoading(false);
              });
        }
        if (authUser.user && isAdminPath) {
            setLoading(true);
            getAdmin();
        }
    }, [isAdminPath, firestoreInstance]);

    return (
        <>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} >
                <Grid size={{ xs: 12, md: 12 }}>
                    {error !== null &&
                        <Alert severity="error">{error}</Alert>
                    }
                    {prompt !== null &&
                        <Alert severity="info">
                            {prompt}
                        </Alert>
                    }
                </Grid>
            </Grid>
        </Box>
        {!loading && admin ? (
            <AdminPageContext.Provider value={{admin}}>
                <Outlet />
            </AdminPageContext.Provider>
        ) : (<>
            {loading ? (<BorderLinearProgress />) : (
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Permission Needed.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            )}
            </>
        )}
        </>
    )

}