import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination, Stack } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useEffect, useState, useCallback } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, and, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, limit, startAfter, startAt, endBefore, getCountFromServer, orderBy } from 'firebase/firestore';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import {GroupPageContext} from "./GroupPageContext";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

import EventTimeline from "../event/EventTimeline";

export const GroupEvents = () => {
    //console.log('group events');
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { currentGroup } = useContext(GroupPageContext);
    //console.log('currentGroup', currentGroup);

    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);
    const [ events, setEvents ] = useState([]);
    const [selectedDayEvents, setSelectedDayEvents] = React.useState([]);

    const [selectedDay, setSelectedDay] = React.useState(dayjs(new Date()));
    const [selectedYearMonth, setSelectedYearMonth] = React.useState(selectedDay.format('M')+' '+selectedDay.format('YYYY'));
    const [highlightedDays, setHighlightedDays] = React.useState([]);

    const selectDay = (day) => {
        setSelectedDay(day);
    }

    const selectMonth = (month) => {
        //console.log('month', month.format('MMM')+' '+month.format('YYYY'));
        setSelectedYearMonth(month.format('M')+' '+month.format('YYYY'));
        //setSelectedDay(dayjs(new Date())); // for showing appointments of the day
    }

    function ServerDay(props) {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
      
        const isSelected =
          !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
      
        return (
          <Badge
            key={props.day.toString()}
            overlap="circular"
            badgeContent={isSelected ? '⭐️' : undefined}
          >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
          </Badge>
        );
    }

    function getStartOfMonth(year, month) {
        const startDate = new Date(year, month - 1, 1); // month is 0-indexed
        return Math.floor(startDate.getTime() / 1000);
    }

    function getEndOfMonth(year, month) {
        const endDate = new Date(year, month, 0); // month is 0-indexed, day 0 gives the last day of the previous month
        endDate.setHours(23, 59, 59, 999); // set to the end of the day
        return Math.floor(endDate.getTime() / 1000);
    }

    const getHighlightedDays = useCallback(async (yearMonth) => {
        //console.log('yearMonth', yearMonth);
        //get event days of the month

        const [month, year] = yearMonth.split(' ').map(Number);
        const startOfMonth = getStartOfMonth(year, month);
        const endOfMonth = getEndOfMonth(year, month);

        //console.log('startOfMonth', startOfMonth, 'endOfMonth', endOfMonth);

        const eventsRef = collection(firestoreInstance, '/events');
        const eventsQuery = query(eventsRef, 
            and(where('start', '>=', startOfMonth), where('start', '<=', endOfMonth), where('group_id', '==', currentGroup.id)), orderBy("start", "asc"));
        let days = [];
        let groupEvents = [];
        await Promise.all([getDocs(eventsQuery)]).then(([snapshot]) => {
            snapshot.forEach(record => {
                const event = record.data();
                event.id = record.id;
                groupEvents.push(event);
                const eventDate = new Date(event.start * 1000); // Convert to milliseconds
                const day = eventDate.getDate();
                //console.log('event', event, 'day', day);
                days.push(day);
            });
        }).catch(error => {
            console.log('error', error);
        });
        setEvents(groupEvents);
        //console.log('groupEvents', groupEvents);
        //str to int
        setHighlightedDays(days.map(i=>Number(i)));
    }, [selectedYearMonth]);
    
    useEffect(() => {
        getHighlightedDays(selectedYearMonth);
    },[getHighlightedDays]);

    const getEventsOnSelectedDay = useCallback(async (date) => {
        //get events of the day
        //console.log('get events of ', date.format('MMM D'), date.format('YYYY'));
        // read from firestore DB
        // Set the time to the start of the day (00:00:00)
        const startOfDayUnix = date.hour(0).minute(0).second(0).millisecond(0).unix();
        const endOfDayUnix = date.hour(23).minute(59).second(59).millisecond(999).unix();
        //console.log('startOfDayUnix', startOfDayUnix, 'endOfDayUnix', endOfDayUnix);

        const eventsRef = collection(firestoreInstance, '/events');
        const eventsQuery = query(eventsRef, 
            and(where('start', '>=', startOfDayUnix), where('start', '<=', endOfDayUnix), where('group_id', '==', currentGroup.id)));
        const events = [];
    
        await Promise.all([getDocs(eventsQuery)]).then(([snapshot]) => {
            snapshot.forEach(record => {
                const event = record.data();
                event.id = record.id;
                console.log('selected day event', event);
                events.push(event);
            });
        }).catch(error => {
            console.log('error', error);
        });
    
        setSelectedDayEvents(events);
    }, [selectedDay]);
    
    useEffect(() => {
        getEventsOnSelectedDay(selectedDay);
    },[getEventsOnSelectedDay]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} 
                /*sx={{
                    '--Grid-borderWidth': '1px',
                    borderTop: 'var(--Grid-borderWidth) solid',
                    borderLeft: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    '& > div': {
                    borderRight: 'var(--Grid-borderWidth) solid',
                    borderBottom: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    },
                }}*/
            >
                <Grid size={{ xs: 12, md: 12 }}>
                    {error !== null &&
                        <Alert severity="error">{error}</Alert>
                    }
                    {prompt !== null &&
                        <Alert severity="info">
                            {prompt}
                        </Alert>
                    }
                </Grid>
                <Grid size={{ xs: 12, md: 8 }}>
                    <Typography variant="h5">
                        {currentGroup.groupName}
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }} display="flex" justifyContent="right" alignItems="center">
                    {currentGroup.isGroupCreator ? (
                        <>
                        <Typography>You're the Creator</Typography>
                        </>
                    ) : (
                        <>
                        {currentGroup.permission ? (
                            <Typography>
                                {currentGroup.permission == 'free' && (
                                    <>Basic Access</>
                                )}
                                {currentGroup.permission == 'member' && (
                                    <>Member Access</>
                                )}
                                {currentGroup.permission == 'vip' && (
                                    <>VIP Access</>
                                )}
                            </Typography>
                        ) : (
                            <Button variant="outlined" startIcon={<PersonAddIcon />} 
                            onClick={()=>{
                                navigate(config.pathnames.JoinGroup.replace(":groupSlug", groupSlug));
                            }}
                            >
                                Join
                            </Button>
                        )}
                        </>
                    )}
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                    <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                    >
                        <Typography>
                            Group Events
                        </Typography>
                        {currentGroup.isGroupCreator && (
                            <Button variant="outlined" startIcon={<EditCalendarIcon />} 
                            onClick={()=>{
                                navigate(config.pathnames.CreateEvent.replace(":groupSlug", groupSlug));
                            }}
                            >
                                Create an Event
                            </Button>
                        )}
                    </Stack>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar value={selectedDay} onMonthChange={(newValue) => selectMonth(newValue)} onChange={(newValue) => selectDay(newValue)} 
                                slots={{
                                day: ServerDay,
                                }}
                                slotProps={{
                                day: {
                                    highlightedDays,
                                },
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 8 }} sx={{
                    '--Grid-borderWidth': {xs:0, md:'1px'},
                    borderLeft: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    '& > div': {
                    borderColor: 'divider',
                    },
                }}>
                    {events &&
                    <Box>
                        <EventTimeline events={events} selectedDayEvents={selectedDayEvents}/>
                    </Box>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}