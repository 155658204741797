import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination, Stack } from "@mui/material";
import Grid from '@mui/material/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate, useParams } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, orderBy } from 'firebase/firestore';
import { AppContext } from "../../lib/AppProvider";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../payment/CheckoutForm";
import { httpsCallable } from "firebase/functions";
import {EventPageContext} from "./EventPageContext";

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import CheckIcon from '@mui/icons-material/Check';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';

export const EventGetAccess = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const { eventSlug } = useParams();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { event, setEvent, access, setAccess } = useContext(EventPageContext);
    //console.log('event', event);
    const eventGroup = event.eventGroup;

    const [payAmount, setPayAmount] = React.useState(config.singleAccessPrice);
    const [couponCode, setCouponCode] = React.useState(null);
    const [showPayMethod, setShowPayMethod] = React.useState(false);
    const [loadingCC, setLoadingCC] = useState(false);
    const [loadingFC, setLoadingFC] = useState(false);
    
    const [payButtonDisabled, setPayButtonDisabled] = useState(false);
    const [isCouponValid, setIsCouponValid] = useState(false);
    const [isFreeAccessValid, setIsFreeAccessValid] = useState(false);
    const [showPayNowButton, setShowPayNowButton] = useState(true);
    const [discount, setDiscount] = useState(null);

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [prompt, setPrompt] = useState(null);

    const stripePromise = loadStripe("pk_test_51L44EYAZ8dJdmsUfoGp1AyJ5Mm6jQYpGlUDKUSPdCptoTAyVBmQ03bwhkM0jIh2sgLHy9e65MeQK6WpqAjp736SO00aV0psDqO");
    const [clientSecret, setClientSecret] = useState("");
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    const paymentFormIsReady = () => {
        
    }
    
    const paymentCallback = () => {
        setShowPayMethod(false);
        setAccess(true);
        //window.location.href = pathnames.Event.replace(":eventSlug", eventSlug);
        navigate(pathnames.Event.replace(":eventSlug", eventSlug));
    }

    const payNow = (amount, groupId, eventId) => {
        setLoadingCC(true);
        setPayButtonDisabled(true);
        const createCheckoutSession = httpsCallable(functionsInstance, 'createcheckoutsession');
        //console.log('createCheckoutSession ', createCheckoutSession);
        createCheckoutSession({ items: [{ amount: amount }], groupId: groupId, eventId: eventId }).then((res) => {
            //console.log('res ', res);
            setClientSecret(res.data.clientSecret)
            setShowPayMethod(true);
            setLoadingCC(false);
        }).catch(error => {
            //setError(error.message);
            //setProcessing(false);
            console.log('error ', error);
        });
    }

    const handleApplyFreeAccessCode = () => {
        setLoadingFC(true);
        const applyFreeAccessCode = httpsCallable(functionsInstance, 'applyfreeaccesscode');
        applyFreeAccessCode({ couponCode: couponCode, eventId: event.id }).then((res) => {
            console.log('res ', res, res.data.access);
            if (res.data.access == true) {
                setAccess(true);
                navigate(pathnames.Event.replace(":eventSlug", eventSlug));
            } else {
                setLoadingFC(false);
                setError('Invalid Free Access Code.');
            }
        }).catch(error => {
            setLoadingFC(false);
            console.log('error ', error);
        });
    }

    const handleApplyCouponCode = () => {
        console.log('Coupon Code', couponCode);
        setIsCouponValid(false);
        setIsFreeAccessValid(false);
        //get coupon
        const couponRef = doc(firestoreInstance, 'events/'+event.id+'/coupons/', couponCode);
        getDoc(couponRef).then(docSnap => {
            const couponData = docSnap.data();
            console.log('couponData', couponData);
            if (couponData.value) {
                if (couponData.value == 100) {
                    setShowPayNowButton(false);
                    setIsFreeAccessValid(true);
                } else {
                    setShowPayNowButton(true);
                    setIsCouponValid(true);
                    setError(null);
                    let newAmount = config.singleAccessPrice * (1 - couponData.value/100);
                    setPayAmount(newAmount);
                    setDiscount(couponData.value);
                }
            };
        }).catch(error => {
            //console.log('error', error);
            setIsCouponValid(false);
            setIsFreeAccessValid(false);
            setError('Invalid Coupon Code.');
        });
    }

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const couponValue = urlSearchParams.get('coupon');
        setCouponCode(couponValue);
    }, []);

    return (<>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} 
                sx={{
                    '--Grid-borderWidth': '1px',
                    borderTop: 'var(--Grid-borderWidth) solid',
                    borderLeft: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    '& > div': {
                      borderRight: 'var(--Grid-borderWidth) solid',
                      borderBottom: 'var(--Grid-borderWidth) solid',
                      borderColor: 'divider',
                    },
                }}
            >
            <Grid size={{ xs: 12, md: 4 }}>
                <Typography>
                    {event && event.name}
                </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
                <Typography>
                    {access ? (
                        <>Welcome to {event && event.name}</>
                    ) : (
                        <Box sx={{ width: '100%' }}>
                            {showPayMethod ? (
                            <Elements options={options} stripe={stripePromise}>
                                <CheckoutForm paymentCallback={paymentCallback} paymentFormIsReady={paymentFormIsReady}/>
                            </Elements>
                            ) : (<>
                            {showPayNowButton && <Typography>${payAmount} for access</Typography>}
                            <Box sx={{py:2}}>
                                <Box sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <InputBase
                                        sx={{ ml: 1, flex: 1, borderBottom: '1px solid' }}
                                        placeholder="Have a Coupon? Enter Here."
                                        inputProps={{ 'aria-label': 'enter coupon code' }}
                                        value={couponCode}
                                        onChange={e => setCouponCode(e.target.value)}
                                    />
                                    <Button sx={{ ml: '10px' }} variant="outlined" startIcon={<ConfirmationNumberIcon />} 
                                    disabled={couponCode ? false : true}
                                    size="small"
                                    onClick={handleApplyCouponCode}>
                                        Apply
                                    </Button>
                                </Box>
                                <Box sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}>
                                {error !== null &&
                                    <Alert severity="error">{error}</Alert>
                                }
                                {isCouponValid &&
                                    <Alert severity="success">Your {discount && discount }% discount coupon is applied! </Alert>
                                }
                                {isFreeAccessValid &&
                                    <Alert severity="success">Your Free Access Code is valid! </Alert>
                                }
                                </Box>
                            </Box>

                            {showPayNowButton ? (
                                <LoadingButton variant="outlined" startIcon={<CreditCardIcon />} onClick={() => {
                                    payNow(payAmount, event.group_id, event.id);
                                }} loading={loadingCC} loadingPosition="start" disabled={payButtonDisabled}>
                                Pay Now
                                </LoadingButton>
                            ) : (
                                <LoadingButton variant="outlined" startIcon={<EventAvailableOutlinedIcon />} onClick={handleApplyFreeAccessCode} loading={loadingFC} loadingPosition="start">
                                Continue
                                </LoadingButton>
                            )}
                            </>)}
                        </Box>
                    )}
                </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
                <Typography>
                    Do you have access code? Enter here.
                </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
                <Typography>
                    4
                </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 8 }}>
                <Typography>
                    8
                </Typography>
            </Grid>
            </Grid>
        </Box>
    </>)
}