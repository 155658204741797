import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../lib/AppProvider";
import { AuthContext, AuthProvider, AuthRoutes } from "../lib/Auth";

const CoverPage = () => {
    const {config} = useContext(AppContext);
    const { authUser } = useContext(AuthContext);
    console.log('authUser', authUser);
    return (
        <>
            Cover Page {config.brand}
        </>
    )
}
export default CoverPage;