import React, { useEffect, useState } from "react";
import countryJson from "./countries.json";
import axios from 'axios';

export const VisitorAPIContext = React.createContext();

export const VisitorAPIComponents = ({projectId, handleCountryChange, handleStateChange, defaultCountryCode, defaultStateCode, children}) => {
    const [countryObj, setCountryObj] = useState(null); // country object in the json file
    const [stateObj, setStateObj] = useState(null); // state object in the json file
    const countries = countryJson.countries;

    function vAPI(projectId, successHandler, failureHandler){
        if(typeof(successHandler) === "undefined" && typeof(failureHandler) === "undefined"){
            return new Promise(function(resolve, reject){
                axios({
                    method: 'get',
                    url: 'https://api.visitorapi.com/api/?pid='+projectId,
                }).then(function(response){
                    resolve(response.data.data);
                }).catch(function(error){
                    if(error.response && error.response.data && error.response.data.result){
                        reject(error.response.data.result);
                    }else{
                        reject(error);
                    }
                })
            })
        }else{
            axios({
                method: 'get',
                url: 'https://api.visitorapi.com/api/?pid='+projectId,
            }).then(function(response){
                if(typeof(successHandler) === 'function'){
                    successHandler(response.data.data);
                }
            }).catch(function(error){
                if(typeof(failureHandler) === 'function'){
                    failureHandler(error);
                }
            });
        }
    }

    useEffect(() => {
        /**
         * Get the country object that matches the country code
         * @param {string} countryCode 
         * @returns 
         */
        const getCountryObj = (countryCode) => {
            const v = countries.find(obj =>{
                return obj.code === countryCode
            });
            if(typeof(v) === 'undefined'){
                return null
            }else{
                return v
            }
        }

        /**
         * Get the state object that matches the state code in the country object, if the country doesn't have a state list, return null
         * @param {object} countryObj 
         * @param {string} stateCode 
         * @returns 
         */
        const getStateObj = (countryObj, stateCode) => {
            if(countryObj.states){
                const v = countryObj.states.find(obj => {
                    return obj.code === stateCode.toUpperCase()
                });
                if(typeof(v) === 'undefined'){
                    return null
                }else{
                    return v
                }
            }else{
                return {code: stateCode, label: stateCode}
            }
        }

        if(defaultCountryCode){
            // use default values
            const c = getCountryObj(defaultCountryCode)
            setCountryObj(c);
            if(defaultStateCode){
                setStateObj(getStateObj(c, defaultStateCode));
            }
        }else{
            if(typeof(projectId) !== 'undefined' && projectId.trim() !== ''){
                vAPI(projectId).then(data => {
                    console.log('ip data', data);
                    const c = getCountryObj(data.countryCode)
                    setStateObj(getStateObj(c, data.region));
                    setCountryObj(c);
                }).catch(error => {
                    // error, do nothing
                })
            }    
        }
    },[projectId, countries, defaultCountryCode, defaultStateCode]);

    useEffect(() => {
        handleCountryChange(countryObj);
    }, [countryObj, handleCountryChange]);

    useEffect(() => {
        handleStateChange(stateObj);
    }, [stateObj, handleStateChange])

    return (
        <VisitorAPIContext.Provider value={{
            countryObj, setCountryObj, stateObj, setStateObj
        }}>
            {children}
        </VisitorAPIContext.Provider>
    );
}