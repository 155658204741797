import React, { useContext, useEffect, useState } from "react";
import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination, Stack } from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, {timelineOppositeContentClasses,} from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Grid from '@mui/material/Grid2';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { blueGrey, green, red, amber } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../lib/Auth";
import { AppContext } from "../../lib/AppProvider";
import dayjs from 'dayjs';

const timeLineIsEventOfTodayColor = amber[50];

function TimelineCard(props) {
  const { item, isEventOfTheDay, ...other } = props;
  //console.log('item', item);

  const { config } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <Card sx={isEventOfTheDay ? { width: '100%',bgcolor: timeLineIsEventOfTodayColor }:{ width: '100%' }}>
      <CardContent>
        <Grid container spacing={0}>
            <Grid xs={12} md={12}>
                <Typography color="text.secondary" component="span" sx={{cursor: 'pointer', display: 'inline-flex', alignItems: 'flex-start', width: '100%'}} onClick={()=>{
                    navigate(config.pathnames.Event.replace(":eventSlug", item.slug));
                }}>
                    <EventNoteIcon sx={{mr:1}}/>{item.name}
                </Typography>
            </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default function EventTimeline(props) {
  const events = props.events;
  const selectedDayEvents = props.selectedDayEvents || [];
  console.log('events timeline', events);
  console.log('selected day events timeline', selectedDayEvents);
  return (<>
    { (events === undefined || events.length == 0) ? (
        <Box>No Events.</Box>
    ) : (
        <Timeline sx={{px: 2, py: 0, [`& .${timelineOppositeContentClasses.root}`]: {flex: 0.2,},}}>
            {events.map((item, index) => (
                <TimelineItem>
                    <TimelineOppositeContent
                    sx={{ m: 'auto 0', flex: 0, pl: 0 }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                    >
                    <Typography>{dayjs.unix(item.start).format('LT')}</Typography>
                    <Typography>{dayjs.unix(item.start).format('L')}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: '#777' }} />
                    <TimelineDot sx={{ backgroundColor: '#777' }} />
                    <TimelineConnector sx={{ bgcolor: '#777' }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', pl: 2, pr: 0 }}>
                        <TimelineCard item={item} isEventOfTheDay={selectedDayEvents.some(event => event.id === item.id)}/>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    )}
  </>);
}
