import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, styled, TextField, Button } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import { httpsCallable } from "firebase/functions";

import {GroupPageContext} from "./GroupPageContext";

export const CancelGroupSubscription = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();
    const [ input, setInput ] = useState("");
    const [ processing, setProcessing ] = useState(false);

    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { currentGroup } = useContext(GroupPageContext);
    //console.log('currentGroup', currentGroup);

    return (
        <>
            {(!currentGroup.isGroupCreator && currentGroup.groupSubscriptionId) ? (<>
                {error !== null &&
                    <Alert severity="error">{error}</Alert>
                }
                {prompt !== null &&
                    <Alert severity="info">
                        {prompt}
                    </Alert>
                }
                <Box>{currentGroup.groupName} Cancel Subscription</Box>
                <Box p={2}>
                    <Typography component="p" align="center" size="small">
                        Type in <strong>{groupSlug}</strong> and click the "Cancel Subscription" button to confirm the cancellation.
                        This action cannot be undone.
                    </Typography>
                    <TextField required fullWidth name="title" type="text" margin="normal" onChange={e => setInput(e.target.value)} />
                </Box>
                <Box p={2}>
                <Grid container spacing={0} >
                    <Grid display="flex" justifyContent="left" alignItems="center" size="grow">
                        <Button type="button" color="secondary" variant="outlined" disabled={processing} onClick={() => navigate(config.pathnames.GroupMembershipInvoices.replace(":groupSlug", groupSlug))} >Back</Button>
                    </Grid>
                    <Grid display="flex" justifyContent="right" alignItems="center" size="grow">
                            <Button type="button" color="error" variant="contained" disabled={processing} onClick={() => {
                                setError(null);
                                setProcessing(true);
                                if(input !== groupSlug){
                                    setError("The input confirmation does not match \""+groupSlug+"\"");
                                    setProcessing(false);
                                }else{
                                    const cancelGroupSubscription = httpsCallable(functionsInstance, 'cancelgroupsubscription');
                                    return cancelGroupSubscription({
                                        groupId: currentGroup.id
                                    }).then(() => {
                                        // redirect
                                        navigate(config.pathnames.ListGroups);
                                    }).catch(error => {
                                        setError(error.message);
                                        setProcessing(false);
                                    })
                                }
                            }} >Cancel Subscription</Button>
                    </Grid>
                </Grid>
                </Box>
            </>) : (<>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Page Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </>
    )
}