import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, styled, TextField, Button, Stack, Chip } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import { httpsCallable } from "firebase/functions";
import { BillingDetails } from "../payment/BillingDetails";

import {GroupPageContext} from "./GroupPageContext";

export const UpdateMembershipBillingDetails = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { currentGroup } = useContext(GroupPageContext);
    //console.log('currentGroup', currentGroup);

    const [ billingDetails, setBillingDetails ] = useState(null);

    const [loaded, setLoeaded] = useState(false);
    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);
    const [ processing, setProcessing] = useState(false);
    const [ succss, setSuccess] = useState(false);

    useEffect(() => {
        setLoeaded(false);
        setError(null);
        getDoc(doc(firestoreInstance, 'users/'+auth.currentUser.uid)).then(doc => {
            setBillingDetails(doc.data().billingDetails);
            setLoeaded(true);
        }).catch(err => {
            setError(err.message);
            setLoeaded(true);
        })
    },[auth.currentUser.uid, firestoreInstance]);


    return (
        <>
            {(!currentGroup.isGroupCreator && currentGroup.groupSubscriptionId) ? (<>
                <Box>{currentGroup.groupName} Update Billing Details</Box>
                <Box p={2}>
                    <Grid container spacing={0}>
                        <Grid display="flex" justifyContent="right" alignItems="center" size="grow">
                            <Button variant="outlined" size="small" onClick={() => navigate(config.pathnames.GroupMembershipInvoices.replace(":groupSlug", groupSlug))}>Invoice List</Button>
                        </Grid>
                    </Grid>
                    {error !== null?(
                        <Box p={2}>
                            <Alert severity="error">{error}</Alert>
                        </Box>
                    ):(
                        <>
                            {succss === true &&
                                <Box p={2}>
                                    <Alert severity="success">Billing details have been updated successfully.</Alert>
                                </Box>
                            }
                            <Box p={2}>
                                <BillingDetails disabled={processing} buttonText={"Update"} currentBillingDetails={billingDetails} setBillingDetailsObject={(obj) => {
                                    setProcessing(true);
                                    setSuccess(false);
                                    const changeBillingDetails = httpsCallable(functionsInstance, 'changebillingdetails');
                                    changeBillingDetails({billingDetails: obj}).then(() => {
                                        setBillingDetails(obj);
                                        setProcessing(false);
                                        setSuccess(true);
                                    }).catch(error => {
                                        setError(error.message);
                                        setProcessing(false);
                                        setSuccess(false);
                                    })
                                }}/>
                            </Box>
                        </>
                    )}
                </Box> 
            </>) : (<>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Page Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </>
    )
}