import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination, Stack, TextField } from "@mui/material";
import Grid from '@mui/material/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate, useParams } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, orderBy } from 'firebase/firestore';
import { AppContext } from "../../lib/AppProvider";
import {EventPageContext} from "./EventPageContext";

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import GroupIcon from '@mui/icons-material/Group';

export const EventUpdate = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const { eventSlug } = useParams();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { event, setEvent, access, isCreator } = useContext(EventPageContext);
    console.log('event', event);
    console.log('access', access);
    console.log('isCreator', isCreator);
    const eventGroup = event.eventGroup;

    const [loading, setLoading] = React.useState(false);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [prompt, setPrompt] = useState(null);

    const [ eventName, setEventName ] = React.useState(event.name);
    const [ eventDescription, setEventDescription ] = React.useState(event.description);
    const [ eventStart, setEventStart ] = useState(event.start);
    const [ eventEnd, setEventEnd ] = useState(event.end);

    const handleUpdateEventButtonClick = async () => {
        //console.log('eventStart', eventStart);
        //console.log('eventName', eventName);
        
        setLoading(true);
        if (authUser.user) {
            // update event
            try {
                const eventData = {
                    name: eventName,
                    description: eventDescription,
                    start: eventStart,
                    end: eventEnd,
                };

                console.log('eventData', eventData);

                const docRef = doc(firestoreInstance, 'events', event.id);
                setDoc(docRef, eventData, { merge: true }).then(()=>{
                    setEvent(prevState => ({...prevState, 
                        name: eventName,
                        description: eventDescription,
                        start: eventStart,
                        end: eventEnd
                    }));
                    navigate(config.pathnames.Event.replace(":eventSlug", eventSlug));
                });
                
            } catch (error) {
                // Handle errors that occur before the add() operation
                setLoading(false);
                setError(error);
                console.error("Error creating event: ", error);
            }
        }
    }


    return (<>
        {isCreator ? (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} 
                    sx={{
                        '--Grid-borderWidth': '1px',
                        borderTop: 'var(--Grid-borderWidth) solid',
                        borderLeft: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        '& > div': {
                        borderRight: 'var(--Grid-borderWidth) solid',
                        borderBottom: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        },
                    }}
                >
                <Grid size={{ xs: 12, md: 8 }}>
                    <Typography>
                        {event && event.name}
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" startIcon={<ArrowBackIcon />} 
                            onClick={()=>{
                                navigate(config.pathnames.Event.replace(":eventSlug", eventSlug));
                            }}
                        >
                            Back to Event
                        </Button>
                    </Stack>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Grid container spacing={0}>
                        <Grid size={12} sx={{width: '100%'}}>
                            <Box sx={{p: 2}}>
                            <TextField id="event-name" label="Name" variant="outlined" placeholder="name of the event" fullWidth onChange={(e) => {setEventName(e.target.value)}} defaultValue={event && event.name}/>
                            </Box>
                        </Grid>
                        <Grid size={12} sx={{width: '100%'}}>
                            <Box sx={{p: 2}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                        <DateTimePicker
                                        label="Start"
                                        onChange={(newValue) => setEventStart(dayjs(newValue).unix())}
                                        defaultValue={dayjs.unix(event.start)}
                                        />
                                        <DateTimePicker
                                        label="End"
                                        onChange={(newValue) => setEventEnd(dayjs(newValue).unix())}
                                        defaultValue={dayjs.unix(event.end)}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid size={12} sx={{width: '100%'}}>
                            <Box sx={{p: 2}}>
                            <TextField id="event-description" label="Description" placeholder="about the event" rows={4} multiline fullWidth onChange={(e) => {setEventDescription(e.target.value)}} defaultValue={event && event.description}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{ xs: 12, md: 8 }}>
                    <Typography>
                        
                    </Typography>
                </Grid>
                <Grid size={12} sx={{width: '100%'}}>
                    <Box sx={{p:2, display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                        <LoadingButton
                            onClick={handleUpdateEventButtonClick}
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<EventAvailableIcon />}
                            variant="outlined"
                        >
                        Update Event
                        </LoadingButton>
                    </Box>
                </Grid>
                </Grid>
            </Box>
        ) : (<>
            Permission Needed.
        </>)}
    </>)
}