import { AppContext } from "../../lib/AppProvider";
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Box } from "@mui/system";
import React, { useContext, useState, useEffect } from "react";
import StarIcon from '@mui/icons-material/Star';
import InputBase from '@mui/material/InputBase';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

export const PricingPlans = ({selectedPlanId, disabled, selectPlan, paymentMethod, supportedPlans}) => {
    //console.log('supportedPlans', supportedPlans);
    const { config } = useContext(AppContext);
    const [couponCode, setCouponCode] = React.useState(null);
    
    let plans;
    if(typeof(supportedPlans) === 'undefined'){
        plans = config.paymentConfig.plans;
    }else{
        plans = supportedPlans;
    }
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const couponValue = urlSearchParams.get('coupon');
        setCouponCode(couponValue);
    }, []);

    return (
        <Grid container spacing={5} alignItems="flex-end">
            {plans.map((plan, i) => (
                plan.legacy === false &&
                <Grid size={{ xs: 12, md: 4 }} key={i}>
                    <Card>
                        <CardHeader 
                            title={plan.title}
                            subheader={plan.popular ? "Most Popular": ""}
                            titleTypographyProps={{ align: 'center' }}
                            action={plan.popular ? <StarIcon color="success" /> : null}
                            subheaderTypographyProps={{
                            align: 'center',
                            }}
                            sx={{
                            backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[700],
                            }}
                        />
                        <CardContent>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'baseline',
                                mb: 2,
                                }}
                            >
                                <Typography variant="h4" color="text.primary">
                                    {plan.currency}
                                </Typography>
                                <Typography component="h2" variant="h3" color="text.primary">
                                    {plan.price}
                                </Typography>
                                <Typography variant="h6" color="text.secondary">
                                    /{plan.frequency}
                                </Typography>
                            </Box>
                            <ul style={{listStyleType: 'none', paddingLeft: '0px'}}>
                            {plan.description.map((line) => (
                                <Typography
                                component="li"
                                variant="subtitle1"
                                align="center"
                                key={line}
                                >
                                    {line}
                                </Typography>
                            ))}
                            </ul>
                        </CardContent>
                        {/*plan.id != 'free' &&
                        <CardContent>
                            <Box sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}>
                                <InputBase
                                    sx={{ ml: 1, flex: 1, borderBottom: '1px solid' }}
                                    placeholder="Add promotion code"
                                    inputProps={{ 'aria-label': 'enter promotion code' }}
                                    value={couponCode}
                                    onChange={e => setCouponCode(e.target.value)}
                                />
                                <Button sx={{ ml: '10px' }} variant="outlined" startIcon={<ConfirmationNumberIcon />} 
                                disabled={couponCode ? false : true}
                                size="small"
                                onClick={()=>{}}>
                                    Apply
                                </Button>
                            </Box>
                        </CardContent>
                        */}
                        <CardActions>
                            <Button fullWidth disabled={disabled || selectedPlanId===plan.id?true:false} variant={plan.popular?"contained":"outlined"} onClick={(e) => {
                                selectPlan(plan);
                            }}>
                                {selectedPlanId===plan.id ?"Current Plan":"Select Plan"}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}