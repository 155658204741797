import React, { useContext, useEffect, useState } from "react";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import { Box, Typography, Button, Stack } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { v4 as uuid4, v5 as uuidv5 } from 'uuid';
import './imagesDropzone.css'

export const ImagesDropzone = ({ setImageList, previewFile, placeholderText, isCircleBanner = false }) => {
   const [imagesRef, setImagesRef] = useState(null);

   const placeholder = placeholderText ? placeholderText : 'Upload Image<br>(required)';
/*
   useEffect(() => {
      //console.log("firestoreInstance ", firestoreInstance);
      //console.log("firebaseApp ", firebaseApp);
      const storage = getStorage();
      // Create a child reference
      const imagesRef = ref(storage, 'images');
      // imagesRef now points to 'images'
      setImagesRef(imagesRef);
      //console.log("imagesRef ", imagesRef);

   }, []);
*/
   const onDrop = (acceptedFiles) => {
      //console.log("imagesRef ", imagesRef);
      if (acceptedFiles.length > 0) {
         const newImages = Array.from(acceptedFiles).map((file) => {
            const uniqueFilename = uuid4();

            return {
               file: file,
               fileName: uniqueFilename,
               //storageRef: ref(imagesRef, uniqueFilename),
               uid: "",
            };
         });

         setImageList((prevState) => [...prevState, ...newImages]);
      }
   };

   const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      onDrop,
      accept: {
         'image/jpeg': ['.jpeg', '.png', '.jpg'],
         'image/png': ['.png']
      },
      noClick: true,
      noKeyboard: true,
   });
   return (
      <Box {...getRootProps()} className={isDragActive ? 'image-drag-zone drag-active' : 'image-drag-zone'}>
         <input {...getInputProps()} />
         <Grid container spacing={2}>
            {!previewFile ? <Grid display="flex" justifyContent="center" alignItems="center" >
               <Stack spacing={1} sx={{ justifyContent: "center", alignItems: "center"}}>
               <svg width="29" height="20" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.4018 4.16071C16.2366 2.50446 14.317 1.42857 12.1429 1.42857C8.6875 1.42857 5.87054 4.15179 5.71875 7.56696C5.69196 8.14732 5.31696 8.65625 4.76786 8.85268C2.82143 9.54018 1.42857 11.3929 1.42857 13.5714C1.42857 16.3348 3.66518 18.5714 6.42857 18.5714H22.8571C25.2232 18.5714 27.1429 16.6518 27.1429 14.2857C27.1429 12.6429 26.2188 11.2143 24.8571 10.4955C24.2589 10.1786 23.9643 9.49107 24.1518 8.83929C24.2411 8.53125 24.2857 8.20089 24.2857 7.85714C24.2857 5.88393 22.6875 4.28571 20.7143 4.28571C20.1652 4.28571 19.6473 4.41071 19.183 4.62946C18.5536 4.92857 17.8036 4.73214 17.4018 4.16071ZM12.1429 0C14.7991 0 17.1473 1.31696 18.5714 3.33929C19.2188 3.03125 19.9464 2.85714 20.7143 2.85714C23.4777 2.85714 25.7143 5.09375 25.7143 7.85714C25.7143 8.33482 25.6473 8.79464 25.5223 9.23214C27.3348 10.1875 28.5714 12.0938 28.5714 14.2857C28.5714 17.442 26.0134 20 22.8571 20H6.42857C2.87946 20 0 17.1205 0 13.5714C0 10.7679 1.79464 8.38839 4.29464 7.50446C4.47768 3.33036 7.91964 0 12.1429 0ZM10.2098 9.49554L13.7813 5.92411C14.058 5.64732 14.5134 5.64732 14.7902 5.92411L18.3616 9.49554C18.6384 9.77232 18.6384 10.2277 18.3616 10.5045C18.0848 10.7812 17.6295 10.7812 17.3527 10.5045L15 8.15179V15C15 15.3929 14.6786 15.7143 14.2857 15.7143C13.8929 15.7143 13.5714 15.3929 13.5714 15V8.15179L11.2188 10.5045C10.942 10.7812 10.4866 10.7812 10.2098 10.5045C9.93304 10.2277 9.93304 9.77232 10.2098 9.49554Z" fill="#828A99" />
               </svg>
               <Typography align="center" dangerouslySetInnerHTML={{ __html: isDragActive ? 'Drop...' : placeholder }} />
               {isCircleBanner && !isDragActive ? <><Typography align="center">This will appear across the top of your Circle Page.</Typography><Typography align="center" sx={{fontSize: "11px"}}>Recommended image dimensions : 1920 x 1080<br />Min. Dimensions (size): 300 x 300<br />File format: PNG or JPG<br />File size: Less than 32MB</Typography></> : ''}
               </Stack>
               </Grid> : ''
            }
            <Button onClick={open} variant="text" color="primary" className='file-button'>
               Select Images...
            </Button>
            {previewFile ? <Button onClick={open} variant="text" color="primary" aria-label="edit" className='file-button-edit'>
               edit
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.5761 1.94297L14.4448 5.06953L18.6355 9.26016L21.762 6.13359C22.3479 5.54766 22.3479 4.59609 21.762 4.01016L19.6948 1.94297C19.1089 1.35703 18.1573 1.35703 17.5714 1.94297H17.5761ZM12.9964 4.39922L16.5167 0.878906C17.6886 -0.292969 19.587 -0.292969 20.7589 0.878906L22.8261 2.94609C23.9979 4.11797 23.9979 6.01641 22.8261 7.18828L19.3058 10.7086L17.548 17.1633C17.2058 18.4102 16.2495 19.3945 15.0073 19.7695L2.10733 23.6367C1.53076 23.8102 0.902638 23.6508 0.480763 23.2242C0.058888 22.7977 -0.105174 22.1742 0.068263 21.5977L3.94014 8.69766C4.31045 7.46016 5.29483 6.49922 6.54639 6.15703L12.9964 4.39922ZM13.1651 5.90859L6.94014 7.60547C6.19014 7.81172 5.59951 8.38359 5.3792 9.12891L1.88701 20.7586L7.05264 15.593C6.79014 15.1477 6.63545 14.6273 6.63545 14.0695C6.63545 12.4148 7.98076 11.0695 9.63545 11.0695C11.2901 11.0695 12.6355 12.4148 12.6355 14.0695C12.6355 15.7242 11.2901 17.0695 9.63545 17.0695C9.07764 17.0695 8.55733 16.9195 8.11201 16.6523L2.94639 21.818L14.5761 18.3305C15.3214 18.1055 15.898 17.5148 16.0995 16.7695L17.7964 10.5445L13.1605 5.90859H13.1651ZM9.63545 12.5695C9.23763 12.5695 8.8561 12.7276 8.57479 13.0089C8.29349 13.2902 8.13545 13.6717 8.13545 14.0695C8.13545 14.4674 8.29349 14.8489 8.57479 15.1302C8.8561 15.4115 9.23763 15.5695 9.63545 15.5695C10.0333 15.5695 10.4148 15.4115 10.6961 15.1302C10.9774 14.8489 11.1355 14.4674 11.1355 14.0695C11.1355 13.6717 10.9774 13.2902 10.6961 13.0089C10.4148 12.7276 10.0333 12.5695 9.63545 12.5695Z" fill="white" />
               </svg>
            </Button> : ''}
         </Grid>
      </Box>
   );
};
