import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, TextField } from "@mui/material";
import Grid from '@mui/material/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate, useParams } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, orderBy } from 'firebase/firestore';
import { httpsCallable } from "firebase/functions";
import { AppContext } from "../../lib/AppProvider";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ScheduleIcon from '@mui/icons-material/Schedule';

import {GroupPageContext} from "../group/GroupPageContext";

export const CreateEvent = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();
    const { currentGroup } = useContext(GroupPageContext);

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const [loading, setLoading] = React.useState(false);
    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);
    const [ eventName, setEventName ] = React.useState('');
    const [ eventDescription, setEventDescription ] = React.useState('');
    const [ eventStart, setEventStart ] = useState(null);
    const [ eventEnd, setEventEnd ] = useState(null);

    const handleCreateEventButtonClick = async () => {
        //console.log('eventStart', eventStart);
        //console.log('eventName', eventName);
        setLoading(true);
        if (authUser.user) {
            // create a event
            try {
                /*
                const eventData = {
                    name: eventName,
                    description: eventDescription,
                    start: eventStart,
                    end: eventEnd,
                    created: dayjs().unix(),
                    creator_uid: authUser.user.uid,
                };

                console.log('eventData', eventData);
                
                const docRef = await addDoc(collection(firestoreInstance, "events"), eventData);
                console.log("Document written with ID: ", docRef.id);
                */

                const createEventCall = httpsCallable(functionsInstance, 'createevent');
                createEventCall({
                    eventName: eventName,
                    eventDescription: eventDescription,
                    eventStart: eventStart,
                    eventEnd: eventEnd,
                    groupId: currentGroup.id,
                }).then((res) => {
                    console.log('create event res', res);
                    //setLoading(false);
                    console.log('url', config.pathnames.Event.replace(":eventSlug", res.data.slug));
                    navigate(config.pathnames.Event.replace(":eventSlug", res.data.slug));
                }).catch(err => {
                    console.log('err', err);
                    setError(err.message);
                    setLoading(false);
                });
            } catch (error) {
                // Handle errors that occur before the add() operation
                setLoading(false);
                setError(error);
                console.error("Error creating event: ", error);
            }
        }
    }

    /*
    useEffect(() => {
        console.log('currentGroup', currentGroup);
    },[authInstance, firestoreInstance]);
    */

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} 
                sx={{
                    '--Grid-borderWidth': '1px',
                    borderTop: 'var(--Grid-borderWidth) solid',
                    borderLeft: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    '& > div': {
                      borderRight: 'var(--Grid-borderWidth) solid',
                      borderBottom: 'var(--Grid-borderWidth) solid',
                      borderColor: 'divider',
                    },
                }}
            >
            <Grid size={{ xs: 12, md: 12 }}>
                {error !== null &&
                    <Alert severity="error">{error}</Alert>
                }
                {prompt !== null &&
                    <Alert severity="info">
                        {prompt}
                    </Alert>
                }
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
                <Grid container spacing={0}>
                    <Grid size={12} sx={{width: '100%'}}>
                        <Box sx={{p: 2}}>
                        <TextField id="event-name" label="Name" variant="outlined" placeholder="name of the event" fullWidth onChange={(e) => {setEventName(e.target.value)}} />
                        </Box>
                    </Grid>
                    <Grid size={12} sx={{width: '100%'}}>
                        <Box sx={{p: 2}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                    <DateTimePicker
                                    label="Start"
                                    onChange={(newValue) => setEventStart(dayjs(newValue).unix())}
                                    />
                                    <DateTimePicker
                                    label="End"
                                    onChange={(newValue) => setEventEnd(dayjs(newValue).unix())}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Grid>
                    <Grid size={12} sx={{width: '100%'}}>
                        <Box sx={{p: 2}}>
                        <TextField id="event-description" label="Description" placeholder="about the event" rows={4} multiline fullWidth onChange={(e) => {setEventDescription(e.target.value)}} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid size={{ xs: 12, md: 8 }}>
                <Typography>
                    
                </Typography>
            </Grid>
            <Grid size={12} sx={{width: '100%'}}>
                <Box sx={{p:2, display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                    <LoadingButton
                        onClick={handleCreateEventButtonClick}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<ScheduleIcon />}
                        variant="outlined"
                        disabled={!eventName.length>0}
                    >
                    Create Event
                    </LoadingButton>
                </Box>
            </Grid>
            </Grid>
        </Box>
    )
}
