import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { httpsCallable } from "firebase/functions";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LinkIcon from '@mui/icons-material/Link';

import {GroupPageContext} from "./GroupPageContext";

export const GroupEarnings = () => {

    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { currentGroup } = useContext(GroupPageContext);
    const [loading, setLoading] = React.useState(true);
    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);
    const [ success, setSuccess ] = useState(false);
    console.log('currentGroup', currentGroup);

    const [ earningsData, setEarningsData ] = useState(null);
    const [ balance, setBalance ] = useState(null);
    const [ subscriptionTotal, setSubscriptionTotal ] = useState(null);
    const [ payoutTotal, setPayoutTotal ] = useState(null);

    dayjs.extend(localizedFormat);

    const currencyFormat = (number) => {
        const stripeAmount = number; // 123.45 USD
        const locale = 'en-US'; // Set the desired locale (e.g., 'en-US' for US English)
        const currency = 'USD'; // Set the currency code

        const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2, // Set the number of decimal places
        });

        const formattedAmount = formatter.format(stripeAmount / 100); // Divide by 100 to convert cents to dollars
        //console.log(formattedAmount); // Output: "$123.45"

        return formattedAmount;
    }

    const requestPayment = (requestTransfer) => {
        
        const requestPayment = httpsCallable(functionsInstance, 'requestpayment');
        requestPayment({
            groupId: currentGroup.id,
            groupSlug: groupSlug,
            requestTransfer: requestTransfer
        }).then((res) => {
            console.log('res', res);
            if (res.data && res.data.existing_account) {
                if (requestTransfer && res.data.transfer) {
                    // transfer
                    setBalance(null);
                    setSuccess(currencyFormat(res.data.transfer.amount) + " Transfer Successful.");
                } else if (!requestTransfer && !res.data.transfer) {
                    // open new window
                    //window.open(res.data.url, '_blank').focus();
                    window.location.href = res.data.url;
                } else if (requestTransfer && !res.data) {
                    setError('Complete setting up your Stripe account before requesting a transfer.');
                }
            } else if (res.data && res.data.url) {
                window.location.href = res.data.url;
            }
        });
        
    }

    useEffect(() => {
        if (currentGroup.id) {
            const getGroupSubscriptions = httpsCallable(functionsInstance, 'getgroupsubscriptions');
            getGroupSubscriptions({
                groupId: currentGroup.id
            }).then((res) => {
                console.log('res', res);
                if (res.data) {
                    let subscriptionTotal = 0;
                    let payoutTotal = 0;
                    if (res.data.subscriptions) {
                        res.data.subscriptions.forEach(sub => {
                            subscriptionTotal += sub.subscriptionTotalPaid.amount;
                        });
                    }
                    if (res.data.payouts) {
                        res.data.payouts.forEach(payout => {
                            payoutTotal += payout.amount;
                            //console.log('time', payout.date);
                        });
                    }
                    setBalance(subscriptionTotal - payoutTotal);
                    setSubscriptionTotal(subscriptionTotal);
                    setPayoutTotal(payoutTotal);
                    setEarningsData(res.data);
                    setSuccess(true);
                } else {
                    setPrompt('No subscription to this group yet.');   
                    setSuccess(true);
                }
            }).catch(err => {
                setError(err.message);
            });
        }
        /*
        const groupAccessRef = collection(firestoreInstance, '/groupAccess');
        const q = query(groupAccessRef, where('groupId', '==', currentGroup.id));
        getDocs(q).then(snapshots => {
            if (snapshots.empty) {
                setLoading(false);
            } else {
                snapshots.forEach(async groupAccess => {
                    console.log('groupAccess', groupAccess.id, 'group data', groupAccess.data());
                });
                setLoading(false);
            }
        });
        */
    }, [auth.currentUser.uid, functionsInstance, firestoreInstance, groupSlug, currentGroup]);

    return (
        <>
            {error !== null &&
                <Alert severity="error">{error}</Alert>
            }
            {prompt !== null &&
                <Alert severity="info">
                    {prompt}
                </Alert>
            }
            {success &&
                <Alert severity="success">Retrieved your group subscriptions successfully.</Alert>
            }
            {currentGroup.isGroupCreator ? (<>
                <Box>{currentGroup.groupName} Earnings</Box>
                {earningsData && earningsData.connectedAccount && 
                <>
                    <Typography sx={{textAlign: 'right'}}>
                        <Button variant="text" startIcon={<LinkIcon />} onClick={()=>{requestPayment(false);}}>My Stripe Account</Button>
                    </Typography>
                </>}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} 
                        /*sx={{
                            '--Grid-borderWidth': '1px',
                            borderTop: 'var(--Grid-borderWidth) solid',
                            borderLeft: 'var(--Grid-borderWidth) solid',
                            borderColor: 'divider',
                            '& > div': {
                            borderRight: 'var(--Grid-borderWidth) solid',
                            borderBottom: 'var(--Grid-borderWidth) solid',
                            borderColor: 'divider',
                            },
                        }}*/
                    >
                    <Grid size={{ xs: 12, md: 12 }}>
                        <></>
                    </Grid>
                    <Grid size={{ xs: 12, md: 12 }}>
                        <Typography>Balance</Typography>
                        <Typography>{balance > 0 ? currencyFormat(balance) : currencyFormat(0)}</Typography>
                        {balance > 0 && (
                            <Typography sx={{textAlign: 'right'}}>
                                <Button variant="outlined" startIcon={<AccountBalanceOutlinedIcon />} onClick={()=>{requestPayment(true);}}>{earningsData && earningsData.connectedAccount ? 'Request Payment' : 'Set up Payment Account'}</Button>
                            </Typography>
                        )}
                    </Grid>
                    <Grid size={{ xs: 12, md: 12 }}>
                        <Typography>Subscriptions {currencyFormat(subscriptionTotal)}</Typography>
                        <Typography>
                            {earningsData && earningsData.subscriptions && (<>
                                {earningsData.subscriptions.map(sub => (<>
                                    <Typography>{currencyFormat(sub.subscriptionTotalPaid.amount)}</Typography>
                                </>))}
                            </>)}
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 12, md: 12 }}>
                        <Typography>Payouts {currencyFormat(payoutTotal)}</Typography>
                        <Typography>
                            {earningsData && earningsData.payouts && (<>
                                {earningsData.payouts.map(payout => (<>
                                    <Typography>{currencyFormat(payout.amount)} @ {dayjs.unix(payout.date).format('L LT')}</Typography>
                                </>))}
                            </>)}
                        </Typography>
                    </Grid>
                    </Grid>
                </Box>
            </>) : (<>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Page Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </>
    )
}