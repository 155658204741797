import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect, useCallback } from "react";
import { collection, query, and, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, limit, startAfter, startAt, endBefore, getCountFromServer, orderBy } from 'firebase/firestore';
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import EventTimeline from "./EventTimeline";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

export const ListEvents = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [prompt, setPrompt] = useState(null);

    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(6);

    const [events, setEvents] = useState(null);
    const [selectedDayEvents, setSelectedDayEvents] = React.useState([]);

    const [selectedDay, setSelectedDay] = React.useState(dayjs(new Date()));
    const [selectedYearMonth, setSelectedYearMonth] = React.useState(selectedDay.format('M')+' '+selectedDay.format('YYYY'));
    const [highlightedDays, setHighlightedDays] = React.useState([]);

    const selectDay = (day) => {
        setSelectedDay(day);
    }

    const selectMonth = (month) => {
        //console.log('month', month.format('MMM')+' '+month.format('YYYY'));
        setSelectedYearMonth(month.format('M')+' '+month.format('YYYY'));
        //setSelectedDay(dayjs(new Date())); // for showing appointments of the day
    }

    function ServerDay(props) {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
      
        const isSelected =
          !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
      
        return (
          <Badge
            key={props.day.toString()}
            overlap="circular"
            badgeContent={isSelected ? '⭐️' : undefined}
          >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
          </Badge>
        );
    }

    function getStartOfMonth(year, month) {
        const startDate = new Date(year, month - 1, 1); // month is 0-indexed
        return Math.floor(startDate.getTime() / 1000);
    }

    function getEndOfMonth(year, month) {
        const endDate = new Date(year, month, 0); // month is 0-indexed, day 0 gives the last day of the previous month
        endDate.setHours(23, 59, 59, 999); // set to the end of the day
        return Math.floor(endDate.getTime() / 1000);
    }

    const getHighlightedDays = useCallback(async (yearMonth) => {
        //console.log('yearMonth', yearMonth);
        //get event days of the month

        const [month, year] = yearMonth.split(' ').map(Number);
        const startOfMonth = getStartOfMonth(year, month);
        const endOfMonth = getEndOfMonth(year, month);

        //console.log('startOfMonth', startOfMonth, 'endOfMonth', endOfMonth);

        const eventsRef = collection(firestoreInstance, '/events');
        const eventsQuery = query(eventsRef, 
            and(where('start', '>=', startOfMonth), where('start', '<=', endOfMonth)), orderBy("start", "asc"));
        let days = [];
        let groupEvents = [];
        await Promise.all([getDocs(eventsQuery)]).then(([snapshot]) => {
            snapshot.forEach(record => {
                const event = record.data();
                event.id = record.id;
                groupEvents.push(event);
                const eventDate = new Date(event.start * 1000); // Convert to milliseconds
                const day = eventDate.getDate();
                //console.log('event', event, 'day', day);
                days.push(day);
            });
        }).catch(error => {
            console.log('error', error);
        });
        setEvents(groupEvents);
        //console.log('days', days);
        //str to int
        setHighlightedDays(days.map(i=>Number(i)));
    }, [selectedYearMonth]);
    
    useEffect(() => {
        setLoaded(false);
        getHighlightedDays(selectedYearMonth);
        setLoaded(true);
    },[getHighlightedDays]);

    const getEventsOnSelectedDay = useCallback(async (date) => {
        //get events of the day
        //console.log('get events of ', date.format('MMM D'), date.format('YYYY'));
        // read from firestore DB
        // Set the time to the start of the day (00:00:00)
        const startOfDayUnix = date.hour(0).minute(0).second(0).millisecond(0).unix();
        const endOfDayUnix = date.hour(23).minute(59).second(59).millisecond(999).unix();
        //console.log('startOfDayUnix', startOfDayUnix, 'endOfDayUnix', endOfDayUnix);

        const eventsRef = collection(firestoreInstance, '/events');
        const eventsQuery = query(eventsRef, 
            and(where('start', '>=', startOfDayUnix), where('start', '<=', endOfDayUnix)));
        const events = [];
    
        await Promise.all([getDocs(eventsQuery)]).then(([snapshot]) => {
            snapshot.forEach(record => {
                const event = record.data();
                event.id = record.id;
                console.log('selected day event', event);
                events.push(event);
            });
        }).catch(error => {
            console.log('error', error);
        });
    
        setSelectedDayEvents(events);
    }, [selectedDay]);
    
    useEffect(() => {
        getEventsOnSelectedDay(selectedDay);
    },[getEventsOnSelectedDay]);

    /*
    useEffect(() => {
        setLoaded(false);
        setError(null);
        const eventsRef = collection(firestoreInstance, '/events');
        
        let eventsQuery = query(eventsRef, orderBy("updated", "desc"));

        Promise.all([getDocs(eventsQuery)]).then(async ([snapshot]) => {
            //console.log('snapshot', snapshot.docs.map(doc=>(doc.data())));
            const totalDocs = snapshot.docs.length;
            setTotalRows(totalDocs);

            const events = [];
            snapshot.docs.map(async (eventDoc) => {
                const eventData = eventDoc.data();
                console.log('eventData', eventData);
                events.push(eventData);
            });
            //console.log('events', events);
            setEvents(events);

        }).catch(error => {
            console.log('query error', error);
            setLoaded(true);
            setError(error.message);
        });
    },[authInstance, firestoreInstance]);
    */

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} 
                /*sx={{
                    '--Grid-borderWidth': '1px',
                    borderTop: 'var(--Grid-borderWidth) solid',
                    borderLeft: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    '& > div': {
                      borderRight: 'var(--Grid-borderWidth) solid',
                      borderBottom: 'var(--Grid-borderWidth) solid',
                      borderColor: 'divider',
                    },
                }}*/
            >
                <Grid size={{ xs: 12, md: 4 }}>
                    <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar value={selectedDay} onMonthChange={(newValue) => selectMonth(newValue)} onChange={(newValue) => selectDay(newValue)} 
                                slots={{
                                day: ServerDay,
                                }}
                                slotProps={{
                                day: {
                                    highlightedDays,
                                },
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 8 }}  sx={{
                    '--Grid-borderWidth': {xs:0, md:'1px'},
                    borderLeft: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    '& > div': {
                    borderColor: 'divider',
                    },
                }}>
                    {events &&
                    <Box>
                        <EventTimeline events={events} selectedDayEvents={selectedDayEvents}/>
                    </Box>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}