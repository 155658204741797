import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, styled, Button, Stack } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, orderBy } from 'firebase/firestore';
import currencies from "./currencies.json";
import { PaginationTable } from "./PaginationTable";
import {BorderLinearProgress} from '../common';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';

import {GroupPageContext} from "./GroupPageContext";

export const GroupMembershipInvoices = () => {

    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { currentGroup } = useContext(GroupPageContext);
    const currentGroupSubscriptionId = currentGroup.groupSubscriptionId;
    const [groupSubscription, setGroupSubscription] = useState(null);

    const [loaded, setLoaded] = useState(false);
    const [ invoices, setInvoices ] = useState([]);
    const [ error, setError ] = useState(null);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setError(null);
        setLoaded(false);

        if (currentGroupSubscriptionId) {
            const groupSubscriptionRef = doc(firestoreInstance, 'groupSubscriptions', currentGroupSubscriptionId);
            getDoc(groupSubscriptionRef).then(docSnap => {
                const sub = docSnap.data();
                setGroupSubscription(sub);
                //console.log('group subscription', sub);
                //get invoices of the subscription
                const invoicesRef = collection(firestoreInstance, 'groupSubscriptions/'+currentGroupSubscriptionId+"/invoices");
                const q = query(invoicesRef, orderBy('created', 'desc'));
                getDocs(q).then(invoicesSnapshot => {
                    const records = [];
                    invoicesSnapshot.forEach(invoice => {
                        records.push({
                            'id': invoice.id,
                            'total': (invoice.data().total / 100).toFixed(2),
                            'subTotal': (invoice.data().subTotal / 100).toFixed(2),
                            'tax': ((invoice.data().tax || 0) / 100).toFixed(2),
                            'amountPaid': (Math.round(invoice.data().amountPaid / 100)).toFixed(2),
                            'created': (new Date(invoice.data().created * 1000)).toLocaleString(),
                            'hostedInvoiceUrl': invoice.data().hostedInvoiceUrl,
                            'currency': invoice.data().currency,
                            'status': invoice.data().status,
                            'amountCol': <>{currencies[invoice.data().currency].sign}{(invoice.data().total / 100).toFixed(2)}</>,
                            'statusCol': <>{invoice.data().status.toUpperCase()}</>,
                            'urlCol': invoice.data().hostedInvoiceUrl?(
                                <Button href={invoice.data().hostedInvoiceUrl} rel="noreferrer" target="_blank" variant="contained" size="small">View Invoice</Button>
                            ):(<></>)
                        })
                    })
                    setTotal(records.length);
                    setInvoices(records);
                    setLoaded(true);
                }).catch(err => {
                    setError(err.message);
                    setLoaded(true);
                    console.log('err', err);
                });

            }).catch(error => {
                console.log('error', error);
            });
        } else {
            setLoaded(true);
        }
        
    }, [firestoreInstance, currentGroupSubscriptionId]);

    useEffect(() => {
        const startIndex = page * pageSize;
        const rows = [];
        for(let i=startIndex; i<invoices.length; i++){
            const invoice = invoices[i];
            if(i>=startIndex+pageSize){
                break;
            }
            rows.push(invoice);
        }
        if(rows.length > 0){
            setRows(rows);
        }
        window.scrollTo(0, 0);
    }, [page, pageSize, invoices]);

    return (
        <>
        {loaded ? (
        <>
            {(!currentGroup.isGroupCreator && currentGroup.groupSubscriptionId) ? (<>
                <Box>{currentGroup.groupName} Invoices</Box>
                {!currentGroup.isDeleted ? (
                    <Box p={2}>
                        <Grid container spacing={0} justifyContent="space-between" alignItems="center">
                            <Grid size={{ xs: 12, md: 12 }} textAlign="right">
                                {groupSubscription.ownerId === auth.currentUser.uid && <Stack direction="row-reverse" spacing={1} mt={2}>
                                    <Button color="error" variant="outlined" size="small" onClick={() => navigate(config.pathnames.CancelGroupSubscription.replace(":groupSlug", groupSlug))}>Cancel Subscription</Button>
                                    <Button color="info" variant="outlined" size="small" onClick={() => navigate(config.pathnames.GroupMembershipChangePlan.replace(":groupSlug", groupSlug))}>Chane Plan</Button>
                                    <Button color="info" variant="outlined" size="small" onClick={() => navigate(config.pathnames.GroupMembershipManagePaymentMethods.replace(":groupSlug", groupSlug))}>Update Payment Method</Button>
                                    <Button color="info" variant="outlined" size="small" onClick={() => navigate(config.pathnames.UpdateMembershipBillingDetails.replace(":groupSlug", groupSlug))}>Update Billing Details</Button>
                                </Stack>}
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <>
                    This group will be closed at {dayjs.unix(currentGroup.subscriptionCurrentPeriodEnd).format('L LT')}
                    {/*
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimeField', 'DateTimeField']}>
                            <DateTimeField
                            defaultValue={dayjs.unix(currentGroup.subscriptionCurrentPeriodEnd)}
                            readOnly={true}
                            sx={{
                                "& fieldset": { border: 'none' },
                              }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    */}
                    </>
                )}
                <Box p={2}>
                    <PaginationTable columns={[
                        {name: "Invoice ID", field: "id", style: {width: '30%'}},
                        {name: "Amount", field: "amountCol", style: {width: '15%'}},
                        {name: "Status", field: "statusCol", style: {width: '10%'}},
                        {name: "Invoice Date", field: "created", style: {width: '30%'}},
                        {name: "Invoice URL", field: "urlCol", style: {width: '15%'}}
                    ]}
                    rows={rows}
                    totalRows={total}
                    pageSize={pageSize}
                    page={page}
                    handlePageChane={(e, p) => {
                        setPage(p);
                    }}
                    handlePageSizeChange={(e) => {
                        setPage(0);
                        setPageSize(e.target.value);
                    }}
                    />
                </Box>
            </>) : (<>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Page Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </>
        ) : (
            <BorderLinearProgress />
        )}
        </>
    )
}