import React, { useEffect, useState, useContext } from "react";
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Navigate, Outlet, matchRoutes, useLocation } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { getFirestore, collection, query, and, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, runTransaction } from 'firebase/firestore';
import { AppContext } from "./AppProvider";
import { getFunctions } from "firebase/functions";
import authroutes from './authroutes.json'; // routes need to login

export const AuthContext = React.createContext();

export const AuthProvider = ({children}) => {

    // authorized user state
    const [authUser, setAuthUser] = useState(
        {
            user: null,
            data: {},
            checked: false
        }
    );

    const { config } = useContext(AppContext);
    const [firebaseApp, setFirebaseApp] = useState(null);
    const [authInstance, setAuthInstance] = useState(null);
    const [firestoreInstance, setFirestoreInstance] = useState(null);
    const [functionsInstance, setFunctionsInstance] = useState(null);

    useEffect(() => {
        const app = initializeApp(config.firebaseConfig);
        const auth = getAuth(app);
        const firestore = getFirestore(app);
        const functions = getFunctions(app);

        setFirebaseApp(app);
        setAuthInstance(auth);
        setFirestoreInstance(firestore);
        setFunctionsInstance(functions);
    
        onAuthStateChanged(auth, (user) => {
            if(user !== null){
                user.getIdToken().then(token => {
                    const userDoc = doc(firestore, 'users', user.uid);
                    setAuthUser(prevState => ({
                        ...prevState,
                        user: user,
                        checked: true
                     }));
                    setDoc(userDoc, {
                        displayName: user.displayName,
                        photoURL: user.photoURL,
                        email: user.email
                    },{merge: true}).then(async () => {
                        // get access permission
                        const systemAdminRef = doc(firestore, "systemAdmin", user.uid);
                        await getDoc(systemAdminRef).then(docSnap => {
                            if (docSnap.exists()) {
                                const data = docSnap.data();
                                setAuthUser(prevState => ({
                                    ...prevState,
                                    isAdmin: true,
                                    adminPermission: data.permission
                                }));
                            }
                        });
                        // Create profile in a transaction
                        const profileRef = doc(firestore, "profiles", user.uid);
                        try {
                            await runTransaction(firestore, async (transaction) => {
                                const profileDoc = await transaction.get(profileRef);
                                if (!profileDoc.exists()) {
                                    //console.log('create profile');
                                    transaction.set(profileRef, {
                                        displayName: user.displayName,
                                        photoURL: user.photoURL,
                                        profile_uid: user.uid
                                    });
                                }
                            });
                        } catch (e) {
                            console.log('create profile failure:', e);
                        }
                        /*
                        //create profile
                        const profileQuery = query(collection(firestore, "profiles"), where("profile_uid", "==", user.uid));
                        getDocs(profileQuery).then(snapshot => {
                            if (snapshot.empty) {
                                //create
                                // Add a new document with a generated id
                                const profileRef = doc(collection(firestore, "profiles"));
                                setDoc(profileRef,{
                                    displayName: user.displayName,
                                    photoURL: user.photoURL,
                                    profile_uid: user.uid
                                });
                            }
                        });
                        */
                    });
                });
            }else{
                setAuthUser(prevState => ({
                    ...prevState,
                    user: null,
                    checked: true
                 }));
            }
        });
    },[config.firebaseConfig]);

    return (
        <AuthContext.Provider value={{
            authUser, setAuthUser, firebaseApp, authInstance, firestoreInstance, functionsInstance
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const AuthRoutes = ({ loader }) => {
    const { authUser } = useContext(AuthContext);
    const { config } = useContext(AppContext);
    const signInPath = config.pathnames.SignIn;
    const location = useLocation();
    const matches = matchRoutes(authroutes, location)

    if(authUser.checked){
        if(authUser.user !== null){
            return <Outlet />
        }else{
            if (matches === null) {
                // the path doesn't need log in to access
                return <Outlet />;
            }
            return <Navigate to={signInPath+"?re="+document.location.pathname+document.location.search+document.location.hash} />
        }
    }else{
        return (
            <Box mt={10}>
                <Container maxWidth="sm">
                    <Box component="span" m={5} textAlign="center">
                        {loader}
                    </Box>
                </Container>
            </Box>
        )
    }
}