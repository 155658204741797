import React, { useContext, useState } from "react";
import { styled, alpha, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Drawer, IconButton, Toolbar, Divider, Typography, Button, InputBase, Badge, MenuItem, Menu, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { AppContext } from "../../lib/AppProvider";
import { AuthContext, AuthProvider, AuthRoutes } from "../../lib/Auth";
import { NavLink, useLocation } from "react-router-dom";
import { ListItemIcon } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { getAuth, signOut } from "firebase/auth";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoginIcon from '@mui/icons-material/Login';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import ImageIcon from '@mui/icons-material/Image';
import SubjectIcon from '@mui/icons-material/Subject';
import BarChartIcon from '@mui/icons-material/BarChart';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SubMenu from "./SubMenu";
import EventSubMenu from "./EventSubMenu";
import AdminSubMenu from "./AdminSubMenu";
import NestedList from "./NestedList";
import EventNestedList from "./EventNestedList";
import AdminNestedList from "./AdminNestedList";
import {GroupMenuContext} from "../group/GroupMenuContext";

//https://mui.com/material-ui/react-app-bar/

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export const AppTemplate = (props) => {
  const { authUser } = useContext(AuthContext);
  const { group, event } = useContext(GroupMenuContext);
  const location = useLocation();
  const isAdminPath = location.pathname.startsWith('/admin');
  //console.log("template group ", group);
  //console.log("template event ", event);
  //console.log("template props ", props);
  const { window, logo } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {config} = useContext(AppContext);
  const signInPath = config.pathnames.SignIn;
  const pathnames = config.pathnames;
  const profileUrl = pathnames.UserProfile;
  const groupUrl = pathnames.ListGroups;
  const eventUrl = pathnames.ListEvents;
  const adminUrl = pathnames.Admin;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    
  const navigate = useNavigate();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
      setAnchorEl(null);
      handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
      setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 0,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {profileUrl && [
        <MenuItem key='profile-url' onClick={
            () => {
                handleMenuClose();
                navigate(profileUrl);
            }}>
             <IconButton
               size="small"
               aria-label="account of current user"
               aria-controls="primary-search-account-menu"
               aria-haspopup="true"
               color="inherit"
             >
               <AccountBoxIcon />
             </IconButton>
             Profile
         </MenuItem>
        ]}
        {authUser.user && authUser.hasOwnProperty('isAdmin') && authUser.isAdmin === true && [
         <MenuItem key="admin" onClick={
            () => {
                handleMenuClose();
                navigate(adminUrl);
            }}>
             <IconButton
               size="small"
               aria-label="administrator panel"
               aria-controls="primary-search-account-menu"
               aria-haspopup="true"
               color="inherit"
             >
               <ManageAccountsIcon />
             </IconButton>
             Admin
         </MenuItem>
        ]}
        {authUser.user && (<MenuItem key='logout-url' onClick={(e)=>{
          e.preventDefault();
          handleMenuClose();
          const auth = getAuth();
          signOut(auth).then(() => {
              document.location.href = "/";
          });
        }}>
           <IconButton
             size="small"
             aria-label="account of current user"
             aria-controls="primary-search-account-menu"
             aria-haspopup="true"
             color="inherit"
           >
             <ExitToAppIcon />
           </IconButton>
          Logout
        </MenuItem>)}
      </Menu>
  );

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <NavLink to="/" style={{color: 'inherit', textDecoration:'none'}} key="home">
          <Typography variant="h6" sx={{ my: 2, pl:2 }}>
          {logo} {/*config.brand*/}
          </Typography>
      </NavLink>
      <Divider />
      <List>
        {groupUrl && [
        <NavLink to={groupUrl} style={{textDecoration:'none'}} key="subscription">
          <ListItem key="groups" disablePadding>
            <ListItemButton>
                <ListItemIcon><SubscriptionsIcon /></ListItemIcon>
                <ListItemText primary={<Typography color="textPrimary">Groups</Typography>} />
            </ListItemButton>
          </ListItem>
        </NavLink>
        ]}
        <NavLink to={eventUrl} style={{textDecoration:'none'}} key="event">
           <ListItem key="event" disablePadding>
             <ListItemButton>
               <ListItemIcon><ImageIcon /></ListItemIcon>
               <ListItemText primary={<Typography color="textPrimary">Events</Typography>} />
             </ListItemButton>
           </ListItem>
        </NavLink>
      </List>
      {group &&
        <NestedList />
      }
      {event &&
        <EventNestedList />
      }
      {isAdminPath &&
        <AdminNestedList />
      }
      <Divider key="nested-list"/>
      {!authUser.user && [
         <NavLink to={signInPath+"?re="+document.location.pathname+document.location.search+document.location.hash} style={{textDecoration:'none'}} key="signin">
          <ListItem key="signin" disablePadding>
           <ListItemButton>
               <ListItemIcon><LoginIcon /></ListItemIcon>
               <ListItemText primary={<Typography color="textPrimary">Sign In</Typography>} />
           </ListItemButton>
           </ListItem>
       </NavLink>
      ]}
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
         
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
       <Menu
         anchorEl={mobileMoreAnchorEl}
         anchorOrigin={{
           vertical: 'bottom',
           horizontal: 'right',
         }}
         id={mobileMenuId}
         keepMounted
         transformOrigin={{
           vertical: 'top',
           horizontal: 'right',
         }}
         open={isMobileMenuOpen}
         onClose={handleMobileMenuClose}
         slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 0,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            },
         }}
       >
         <MenuItem key='messages-badge'>
           <IconButton size="large" aria-label="show 4 new mails" color="inherit">
             <Badge badgeContent={4} color="error">
               <MailIcon />
             </Badge>
           </IconButton>
           Messages
         </MenuItem>
         <MenuItem key='notifications-badge'>
           <IconButton
             size="large"
             aria-label="show 18 new notifications"
             color="inherit"
           >
             <Badge badgeContent={18} color="error">
               <NotificationsIcon />
             </Badge>
           </IconButton>
           Notifications
         </MenuItem>
         {profileUrl && [
         <MenuItem key='profile-badge' onClick={
            () => {
                handleMenuClose();
                navigate(profileUrl);
            }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountBoxIcon />
              </IconButton>
              Profile
          </MenuItem>
         ]}
         {authUser.user && authUser.hasOwnProperty('isAdmin') && authUser.isAdmin === true && [
          <MenuItem key="admin-mobile" onClick={
            () => {
                handleMenuClose();
                navigate(adminUrl);
            }}>
              <IconButton
                size="large"
                aria-label="administrator panel"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <ManageAccountsIcon />
              </IconButton>
              Admin
          </MenuItem>
         ]}
         {authUser.user && (
         <MenuItem key='logout-badge' onClick={(e)=>{
              e.preventDefault();
              handleMenuClose();
              const auth = getAuth();
              signOut(auth).then(() => {
                  document.location.href = "/";
              });
         }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <ExitToAppIcon />
              </IconButton>
              Logout
        </MenuItem>)}
       </Menu>
  );

  return (
    <>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <NavLink to="/" style={{color: '#fff', textDecoration:'none'}} key="home">
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 0.1, display: { xs: 'none', md: 'block' }, mr:2 }}
              >
                {logo} {/*config.brand*/}
              </Typography>
          </NavLink>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {groupUrl && [
                <NavLink to={groupUrl} style={{textDecoration:'none'}} key="subscription">
                  <Button key="profile" sx={{ color: '#fff', textTransform: 'none' }}>
                    Groups
                  </Button>
                </NavLink>
            ]}
            <NavLink to={eventUrl} style={{textDecoration:'none'}} key="event">
                <Button key="event" sx={{ color: '#fff', textTransform: 'none' }}>
                  Events
                </Button>
            </NavLink>
            {group &&
              <SubMenu />
            }
            {event &&
              <EventSubMenu />
            }
            {isAdminPath &&
              <AdminSubMenu />
            }
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          {!authUser.user && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button aria-label="sign in" color="inherit" sx={{textTransform: 'none'}} onClick={(e)=>{
                    e.preventDefault();
                    handleMenuClose();
                    document.location.href = signInPath+"?re="+document.location.pathname+document.location.search+document.location.hash
                   }}>
                   <LoginIcon sx={{marginRight: "5px"}}/> Sign In
                </Button>
              </Box>
          )}
          {authUser.user && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                  <Badge badgeContent={4} color="error">
                    <MailIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={17} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Box>
          )}
          {authUser.user && (
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
          )}
        </Toolbar>
      </AppBar>
      {authUser.user && renderMobileMenu}
      {renderMenu}
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
          <Box component="main" sx={{
              flexGrow: 1,
              backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              height: '100vh',
              overflow: 'auto'
          }}>
              <DrawerHeader />
              <Outlet />
          </Box>
    </Box>
    </>
  );
}

