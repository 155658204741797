import React, { useContext, useState, useEffect } from "react";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { AppContext } from "../../lib/AppProvider";
import { NavLink } from "react-router-dom";
import { useParams } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import WebIcon from '@mui/icons-material/Web';
//import PaidIcon from '@mui/icons-material/Paid';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PaymentsIcon from '@mui/icons-material/Payments';
import Divider from '@mui/material/Divider';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { AuthContext } from "../../lib/Auth";

import { GroupMenuContext } from '../group/GroupMenuContext';

export default function EventNestedList(props) {
  const { event, isEventCreator } = useContext(GroupMenuContext);
  const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
  const [openManageGroup, setOpenManageGroup] = React.useState(true);
  const { eventSlug } = useParams();
  const {config} = useContext(AppContext);
  const pathnames = config.pathnames;
  const eventHomeUrl = pathnames.Event.replace(":eventSlug", eventSlug);
  const updateEventsUrl = pathnames.EventUpdate.replace(":eventSlug", eventSlug);
  const eventGroupUrl = pathnames.Group.replace(":groupSlug", event.eventGroup.slug);
  const eventSettingsUrl = pathnames.EventSettings.replace(":eventSlug", eventSlug);

  const handleClick = () => {
    setOpenManageGroup(!openManageGroup);
  };

  return (
    <>
    {eventSlug && (<>
      <Divider key="nested-list-group"/>
       <List
         sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
         component="nav"
         aria-labelledby="nested-list-subheader"
         subheader={
           <ListSubheader component="div" id="nested-list-subheader">
            Event {event.name}
           </ListSubheader>
         }
       >
          {eventHomeUrl && [
              <NavLink to={eventHomeUrl} style={{textDecoration:'none'}} key="event-home">
                  <ListItemButton>
                      <ListItemIcon><GridViewOutlinedIcon /></ListItemIcon>
                      <ListItemText primary={<Typography color="textPrimary">Event Home</Typography>} />
                  </ListItemButton>
              </NavLink>
          ]}
          {eventGroupUrl && [
              <NavLink to={eventGroupUrl} style={{textDecoration:'none'}} key="event-group">
                  <ListItemButton>
                      <ListItemIcon><GroupIcon /></ListItemIcon>
                      <ListItemText primary={<Typography color="textPrimary">Event Group</Typography>} />
                  </ListItemButton>
              </NavLink>
          ]}
          {isEventCreator && (<>
            <ListItemButton onClick={(e)=>{
                e.stopPropagation();
                handleClick();
              }}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Manage" />
              {openManageGroup ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openManageGroup} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {updateEventsUrl && [
                  <NavLink to={updateEventsUrl} style={{textDecoration:'none'}} key="updateevent">
                   <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon><EditCalendarIcon /></ListItemIcon>
                          <ListItemText primary={<Typography color="textPrimary">Update</Typography>} />
                   </ListItemButton>
                  </NavLink>
                ]}
                {eventSettingsUrl && [
                  <NavLink to={eventSettingsUrl} style={{textDecoration:'none'}} key="eventsettings">
                   <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon><EditIcon /></ListItemIcon>
                          <ListItemText primary={<Typography color="textPrimary">Settings</Typography>} />
                   </ListItemButton>
                  </NavLink>
                ]}
              </List>
            </Collapse>
          </>)}
       </List>
      </>)}
    </>
  );
}
