import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, styled } from "@mui/material";
import Grid from '@mui/material/Grid2';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';

import {GroupPageContext} from "./GroupPageContext";

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

export const GroupSettings = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { currentGroup, setCurrentGroup } = useContext(GroupPageContext);
    console.log('currentGroup', currentGroup);

    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);

    const [isPublic, setIsPublic] = React.useState(false);

    const handlePublicAccessChange = (event) => {
        const newStatus = event.target.checked;
        console.log('newStatus', newStatus);
        if (currentGroup.id) {
            const groupDoc = doc(firestoreInstance, 'groups', currentGroup.id);
            setDoc(groupDoc, {
                public: newStatus
            },{merge: true}).then(() => {
                //update currentGroup
                setCurrentGroup(prevState => ({
                    ...prevState,
                    public: newStatus
                }));
                setIsPublic(newStatus);
            });
        }
    };

    useEffect(() => {
        setError(null);
        setIsPublic(currentGroup.public);
    },[auth.currentUser.uid, firestoreInstance, currentGroup]);

    return (
        <>
            {currentGroup.isGroupCreator ? (<>
                {error !== null &&
                    <Alert severity="error">{error}</Alert>
                }
                {prompt !== null &&
                    <Alert severity="info">
                        {prompt}
                    </Alert>
                }
                <Box>{currentGroup.groupName} Settings</Box>
                <Box>
                    <FormGroup>
                        <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} label="Public" labelPlacement="end" checked={isPublic} onChange={handlePublicAccessChange}/>
                    </FormGroup>
                </Box>
                {!currentGroup.public ? (<>
                    Set to public so others can view this group.
                </>) : (<>
                    Turn off public access for maintenance.
                </>)}
            </>) : (<>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Page Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </>
    )
}