import React, { useContext, useEffect, useState } from "react";
import { useParams, Outlet } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import { AppContext } from "../../lib/AppProvider";
import { AuthContext } from "../../lib/Auth";
import { Alert, Box, Container } from "@mui/material";

export const GroupMenuContext = React.createContext();

export const GroupMenuProvider = ({children}) => {
    const { groupSlug } = useParams();
    const { eventSlug } = useParams();
    console.log('groupSlug', groupSlug, 'eventSlug', eventSlug);

    const [ group, setGroup ] = useState(null);
    const [ event, setEvent ] = useState(null);
    const [ eventAccess, setEventAccess ] = useState(false);
    const [ isEventCreator, setIsEventCreator ] = useState(false);

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(AppContext);

    const getGroupSubscription = async (groupId) => {
        let groupSub;
        const uid = authUser.user ? authUser.user.uid : null;
        if (!uid) return null;
        const groupAccessRef = collection(firestoreInstance, '/groupAccess');
        const q = query(groupAccessRef, and(where('groupId', '==', groupId), where('uid', '==', uid)));
        groupSub = await getDocs(q).then(async snapshots => {
            if (snapshots.empty) {
                return null;
            } else {
                const groupAcess = snapshots.docs[0];
                const groupAccessData = groupAcess.data();
                //console.log('groupAccessData', groupAccessData);
                const subscriptionId = groupAccessData.subscriptionId;
                const subDocRef = doc(firestoreInstance, "groupSubscriptions", subscriptionId);
                return await getDoc(subDocRef).then(docSnap => {
                    if (docSnap.exists()) {
                        const groupSubscription = docSnap.data();
                        //console.log('groupSubscription', groupSubscription);
                        if (groupSubscription.subscriptionStatus == 'active' || groupSubscription.subscriptionStatus == 'trialing')
                            return {
                                    planId: groupSubscription.planId,
                                    paymentMethod: groupSubscription.paymentMethod,
                                    subscriptionId: subscriptionId
                            };
                        else return null;
                    } else {
                        return null;
                    }
                }).catch(error => {
                    return null;
                });
            }
        });

        return groupSub;
    }

    useEffect(() => {
        //group
        if (typeof groupSlug !== 'undefined') {
            //console.log('menu groupSlug', groupSlug);
            const groupRef = collection(firestoreInstance, '/groups');
            const q = authUser.user ? query(groupRef, and(where('slug', '==', groupSlug), or(where('public', '==', true), where('creator', '==', authUser.user.uid)))) : query(groupRef, and(where('slug', '==', groupSlug), where('public', '==', true)));
            getDocs(q).then(snapshots => {
                if (snapshots.empty) {
                    //setLoading(false);
                } else {
                    snapshots.forEach(async group => {
                        //console.log('group ID', group.id, 'group data', group.data());
                        setGroup({
                            id:group.id, isGroupCreator: false, ...group.data()
                        });

                        const groupSub = await getGroupSubscription(group.id);
                        if (groupSub) {
                            setGroup({
                                permission: groupSub.planId, ...group.data()
                            });
                        } else {
                            setGroup({
                                permission: null, ...group.data()
                            });
                        }

                        if (authUser.user && authUser.user.uid) {
                            const groupData = group.data();
                            const creatorId = groupData.creator;
                            const userDocRef = doc(firestoreInstance, "users", creatorId);
    
                            if (creatorId == authUser.user.uid) {
                                getDoc(userDocRef).then(docSnap => {
                                    const user = docSnap.data();
                                    //console.log('user', user);
                                    setGroup(prevState => ({
                                        ...prevState,
                                        isGroupCreator: true
                                    }));
                                    //setLoading(false);
                                }).catch(error => {
                                    //setLoading(false);
                                    console.log('error', error);
                                });
                            }
                        }
                    });
                }
            }).catch(error => {
                console.log('query error', error);
            });
        }

        //event
        if (typeof eventSlug !== 'undefined') {
            //console.log('eventSlug', eventSlug);
            const eventRef = collection(firestoreInstance, '/events');
            const q = query(eventRef, where('slug', '==', eventSlug));
            getDocs(q).then(async snapshots => {
                if (snapshots.empty) {
                    //setLoading(false);
                } else {
                    const eventData = snapshots.docs[0].data();
                    const eventId = snapshots.docs[0].id;
                    // access permission
                    //console.log('eventId', eventId);

                    //get event group info
                    const groupRef = doc(firestoreInstance, 'groups', eventData.group_id);
                    const eventGroup = await getDoc(groupRef).then(docSnap => {
                        const group = docSnap.data();
                        //console.log('group', group);
                        return group;
                    }).catch(error => {
                        //no access
                        //console.log('error', error);
                    });

                    setEvent({
                        id: eventId, eventGroup:eventGroup, ...eventData
                    });

                    if (authUser.user && eventData.creator_uid == authUser.user.uid) {
                        setEventAccess(true);
                        setIsEventCreator(true);
                    } else {
                        //1. has group access
                        const groupSub = await getGroupSubscription(eventData.group_id);
                        let hasGroupAccessPermission = false;
                        if (groupSub) {
                            if ( groupSub.planId != 'free' ) {
                                setEventAccess(true);
                                hasGroupAccessPermission = true;
                            }
                            //console.log('group membership', groupSub.planId);
                        }

                        //2. single event access
                        if (!hasGroupAccessPermission) {
                            const accessRef = doc(firestoreInstance, '/events/'+eventId+'/permissions/access');
                            await getDoc(accessRef).then(docSnap => {
                                //const users = docSnap.data();
                                //console.log('users', users);
                                setEventAccess(true);
                            }).catch(error => {
                                //no access
                                //console.log('error', error);
                            });
                        }
                    }

                    //setLoading(false);
                }
            }).catch(error => {
                console.log('query error', error);
            });
        }

    }, [groupSlug, eventSlug, authInstance, firestoreInstance]);

    return (
        <GroupMenuContext.Provider value={{group, setGroup, event, setEvent, eventAccess, setEventAccess, isEventCreator}}>
            {/*children*/}
            <Outlet />
        </GroupMenuContext.Provider>
    )

}