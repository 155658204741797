import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { collection, query, and, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, limit, startAfter, startAt, endBefore, getCountFromServer, orderBy } from 'firebase/firestore';
import {PricingPlans} from '../payment';
import {BorderLinearProgress} from '../common';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export const ListGroups = () => {
    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const createGroupUrl = pathnames.CreateGroup;

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [prompt, setPrompt] = useState(null);

    const [publicGroups, setPublicGroups] = useState([]);
    const [myGroups, setMyGroups] = useState([]);

    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(6);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        const groupsRef = collection(firestoreInstance, '/groups');
        
        let publicGroupQuery = authUser.user ? query(groupsRef, and(where('public', '==', true), where('creator', '!=', authUser.user.uid)), orderBy("updated", "desc")) : query(groupsRef, where('public', '==', true), orderBy("updated", "desc"));

        Promise.all([getDocs(publicGroupQuery)]).then(async ([snapshot]) => {
            //console.log('snapshot', snapshot.docs.map(doc=>(doc.data())));
            const totalDocs = snapshot.docs.length;
            setTotalRows(totalDocs);

            // public groups
            const publicGroupsWithCreators = await Promise.all(snapshot.docs.map(async (groupDoc) => {
                const groupData = groupDoc.data();
                //console.log('groupData', groupData);
                const profileQuery = query(collection(firestoreInstance, "profiles"), where("profile_uid", "==", groupData.creator));
                const querySnapshot = await getDocs(profileQuery);
                //console.log('querySnapshot', querySnapshot);
                const profileData = querySnapshot.docs[0].data();
                //console.log('profileData', profileData);
                return { ...groupData, creatorData: profileData };
            }));

            setPublicGroups(publicGroupsWithCreators);

            if (authUser.user && authUser.user.uid) {
                const myGroupQuery = query(groupsRef, where('creator', '==', authUser.user.uid));
                Promise.all([getDocs(myGroupQuery)]).then(async ([snapshot]) => {
                    //console.log('snapshot', snapshot);
                    const myGroupsWithCreators = await Promise.all(snapshot.docs.map(async (groupDoc) => {
                        const groupData = groupDoc.data();
                        //console.log('groupData', groupData);
                        const profileQuery = query(collection(firestoreInstance, "profiles"), where("profile_uid", "==", groupData.creator));
                        const querySnapshot = await getDocs(profileQuery);
                        //console.log('querySnapshot', querySnapshot);
                        const profileData = querySnapshot.docs[0].data();
                        //console.log('profileData', profileData);
                        return { ...groupData, creatorData: profileData };
                    }));
                    //console.log('myGroupsWithCreators', myGroupsWithCreators);
                    
                    setMyGroups(myGroupsWithCreators);
                    setLoaded(true);
                }).catch(error => {
                    //console.log('query error', error);
                    setLoaded(true);
                    setError(error.message);
                });
            } else {
                setLoaded(true);
            }

        }).catch(error => {
            console.log('query error', error);
            setLoaded(true);
            setError(error.message);
        });
    },[authInstance, firestoreInstance]);

    useEffect(() => {
        //console.log('page', page, 'pageSize', pageSize)
    },[page, pageSize]);
    return (
        <>
        {loaded ? (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} >
                <Grid size={{ xs: 12, md: 12 }}>
                    {error !== null &&
                        <Alert severity="error">{error}</Alert>
                    }
                    {prompt !== null &&
                        <Alert severity="info">
                            {prompt}
                        </Alert>
                    }
                </Grid>
                <Grid size={{ xs: 12, md: 8 }}>
                    
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Typography sx={{textAlign: 'center'}}>
                        <Button variant="text" onClick={()=>{navigate(createGroupUrl);}}>Create a Group</Button>
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                    {myGroups.length > 0 && (
                        <Box>
                            <Grid container spacing={0}>
                                <Grid size={{ xs: 12, md: 12 }}>
                                    <Box sx={{width: '100%'}}>
                                    <Accordion defaultExpanded>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel-mygroup-header"
                                        >
                                        My Groups
                                        </AccordionSummary>
                                        <AccordionDetails sx={{p:0}}>
                                        <Grid container spacing={0}>
                                        {myGroups.map((group, index)=>{console.log(group);return(
                                            <Grid size={{ xs: 12, md: 4 }}>
                                                <Card sx={{ width: '100%' }}>
                                                    <CardHeader
                                                        avatar={
                                                        <Avatar src={group.creatorData.photoURL} />
                                                        }
                                                        action={
                                                        <IconButton aria-label="settings">
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        }
                                                        title={group.groupName}
                                                        subheader={group.creatorData.displayName}
                                                        onClick={()=>{
                                                            navigate(config.pathnames.Group.replace(":groupSlug", group.slug));
                                                        }}
                                                        sx={{cursor: 'pointer'}}
                                                    />
                                                    <CardMedia
                                                        component="img"
                                                        height="194"
                                                        image={group.coverImage ? group.coverImage : config.defaultGroupCover}
                                                        alt={group.groupName}
                                                    />
                                                    <CardContent>
                                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                        {group.description}
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions disableSpacing>
                                                        <IconButton aria-label="add to favorites">
                                                        <FavoriteIcon />
                                                        </IconButton>
                                                        <IconButton aria-label="share">
                                                        <ShareIcon />
                                                        </IconButton>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        )})}
                                        </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                    {publicGroups.length > 0 && (
                        <Box sx={{pt: 2}}>
                            <Grid container spacing={0}>
                                {publicGroups.slice(page * pageSize, page * pageSize + pageSize).map((group, index)=>{/*console.log(group);*/return(
                                    <Grid size={{ xs: 12, md: 4 }}>
                                        <Card sx={{ width: '100%' }}>
                                            <CardHeader
                                                avatar={
                                                <Avatar src={group.creatorData.photoURL} />
                                                }
                                                action={
                                                <IconButton aria-label="settings">
                                                    <MoreVertIcon />
                                                </IconButton>
                                                }
                                                title={group.groupName}
                                                subheader={group.creatorData.displayName}
                                                onClick={()=>{
                                                    navigate(config.pathnames.Group.replace(":groupSlug", group.slug));
                                                }}
                                                sx={{cursor: 'pointer'}}
                                            />
                                            <CardMedia
                                                component="img"
                                                height="194"
                                                image={group.coverImage ? group.coverImage : config.defaultGroupCover}
                                                alt={group.groupName}
                                            />
                                            <CardContent>
                                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {group.description}
                                                </Typography>
                                            </CardContent>
                                            <CardActions disableSpacing>
                                                <IconButton aria-label="add to favorites">
                                                <FavoriteIcon />
                                                </IconButton>
                                                <IconButton aria-label="share">
                                                <ShareIcon />
                                                </IconButton>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                )})}
                            </Grid>
                        </Box>
                    )}
                </Grid>

                <Grid size={{ xs: 12, md: 12 }}>
                    <TablePagination
                        labelRowsPerPage='Groups per page:'
                        rowsPerPageOptions={[6,12,24]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={pageSize}
                        page={page}
                        onPageChange={(e, p) => {
                            //console.log('current page', page, p);
                            /*if (p == 0) {
                                getGroupsByPage(0, 'next');
                            } else {
                                if (page > p) {
                                    fetchPrevPage(p);
                                } else if (page < p) {
                                    fetchNextPage(p);
                                }
                            }*/
                            setPage(p);
                        }}
                        onRowsPerPageChange={(e) => {
                            setPage(0);
                            setPageSize(e.target.value);
                        }}
                    />
                </Grid>
                </Grid>
            </Box>
        ) : (<BorderLinearProgress/>)}
        </>
    )
}