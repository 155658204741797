import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, styled, TextField, Button, Stack, Chip } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import { PaymentMethodForm } from "../payment/PaymentMethodForm";
import { httpsCallable } from "firebase/functions";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import {GroupPageContext} from "./GroupPageContext";

export const ManagePaymentMethods = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const [ paymentMethodFormShowed, setPaymentMethodFormShowed ] = useState(false);
    const [ paymentMethods, setPaymentMethods ] = useState([]);
    const [ paymentFormDisabled, setPaymentFormDisabled ] = useState(false);
    const [ processing, setProcessing ] = useState(false);
    const [loaded, setLoeaded] = useState(false);

    const { currentGroup } = useContext(GroupPageContext);
    console.log('currentGroup', currentGroup);
    const [ subscription, setSubscription ] = useState(false);

    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);

    useEffect(() => {
        setLoeaded(false);
        setError(null);
        setPaymentMethodFormShowed(false);
        // load payment methods of the user
        const paymentMethodsRef = collection(firestoreInstance, 'users/'+auth.currentUser.uid+'/paymentMethods');
        getDocs(paymentMethodsRef).then(paymentMethodsSnapshot => {
            const paymentMethods = [];
            paymentMethodsSnapshot.forEach(paymentMethod => {
                paymentMethods.push({
                    id: paymentMethod.id,
                    type: paymentMethod.data().type,
                    cardBrand: paymentMethod.data().cardBrand,
                    cardExpMonth: paymentMethod.data().cardExpMonth,
                    cardExpYear: paymentMethod.data().cardExpYear,
                    cardLast4: paymentMethod.data().cardLast4
                });
            });
            //console.log('paymentMethods', paymentMethods);
            if(paymentMethods.length === 0){
                setPaymentMethodFormShowed(true);
            }
            setPaymentMethods(paymentMethods);
            
            //get group subscriptions
            const subscriptionRef = doc(firestoreInstance, 'subscriptions', currentGroup.subscriptionId);
            getDoc(subscriptionRef).then(docSnap => {
                const sub = docSnap.data();
                setSubscription(sub);
                setLoeaded(true);
            }).catch(err => {
                setError(err.message);
                setLoeaded(true);
            });
        }).catch(err => {
            setError(err.message);
            setLoeaded(true);
        })
    }, [auth.currentUser.uid, firestoreInstance]);


    return (
        <>
            {currentGroup.isGroupCreator ? (<>
                <Box>{currentGroup.groupName} Manage Payment Methods</Box>
                {loaded && subscription && (
                    <>
                    <Box p={2}>
                        <Grid container spacing={0}>
                            <Grid display="flex" justifyContent="left" alignItems="center" size="grow">
                                <Typography component="h1" variant="h4">Payment Methods</Typography>
                            </Grid>
                            <Grid display="flex" justifyContent="right" alignItems="center" size="grow">
                                <Stack direction="row-reverse" spacing={1} mt={2}>
                                    {!paymentMethodFormShowed && 
                                        <Button variant="outlined" size="small" onClick={() => setPaymentMethodFormShowed(true)}>Add Payment Method</Button>
                                    }
                                    {paymentMethodFormShowed && paymentMethods.length > 0 &&
                                        <Button variant="outlined" size="small" onClick={() => setPaymentMethodFormShowed(false)}>Payment Methods</Button>
                                    }
                                    <Button variant="outlined" size="small" onClick={() => navigate(config.pathnames.GroupInvoices.replace(":groupSlug", groupSlug))}>Invoice List</Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box p={2}>
                        {error !== null?(
                            <Alert severity="error">{error}</Alert>
                        ):(
                            <>
                                {paymentMethodFormShowed?(
                                    <Grid container spacing={2}>
                                    <Grid size={{ xs: 12, md: 12 }} >
                                        <Box p={5}>
                                            <Stack spacing={3}>
                                                <Typography
                                                component="h1"
                                                variant="h5"
                                                align="center"
                                                color="text.primary"
                                                gutterBottom
                                                mb={8}
                                                >
                                                Add Payment Method
                                                </Typography>
                                            </Stack>
                                            <PaymentMethodForm setPaymentMethod={(pm) => {
                                                setError(null);
                                                setPaymentFormDisabled(true);
                                                // write payment method to user
                                                const pmRef = doc(firestoreInstance, 'users/'+auth.currentUser.uid+'/paymentMethods/'+pm.id);
                                                setDoc(pmRef,{
                                                    type: pm.type,
                                                    cardBrand: pm.card.brand,
                                                    cardExpMonth: pm.card.exp_month,
                                                    cardExpYear: pm.card.exp_year,
                                                    cardLast4: pm.card.last4
                                                },{merge:true}).then(() => {
                                                    // attach the payment method to a subscription via cloud function
                                                    const updateSubscriptionPaymentMethod = httpsCallable(functionsInstance, 'updatesubscriptionpaymentmethod');
                                                    return updateSubscriptionPaymentMethod({
                                                        subscriptionId: currentGroup.subscriptionId,
                                                        paymentMethodId: pm.id
                                                    })
                                                }).then(() => {
                                                    // update subscription default payment
                                                    setSubscription(prevState => ({
                                                        ...prevState,
                                                        paymentMethod: pm.id
                                                    }));
                                                    // add payment method to state
                                                    setPaymentMethods(prevState => {prevState.push({
                                                        id: pm.id,
                                                        type: pm.type,
                                                        cardBrand: pm.card.brand,
                                                        cardExpMonth: pm.card.exp_month,
                                                        cardExpYear: pm.card.exp_year,
                                                        cardLast4: pm.card.last4
                                                        });
                                                        return prevState;
                                                    });
                                                    setPaymentFormDisabled(false);
                                                    setPaymentMethodFormShowed(false);
                                                }).catch(err =>{
                                                    setPaymentFormDisabled(false);
                                                    setError(err.message);
                                                })
                                            }} buttonText="Add Payment Method" disabled={paymentFormDisabled} />
                                        </Box>
                                    </Grid>
                                    </Grid>
                                ):(
                                    <Grid container spacing={2}>
                                        {paymentMethods.map((paymentMethod, i) => 
                                            <Grid size={{ xs: 12, md: 4 }} key={i}>
                                                <Card>
                                                    <CardHeader title={
                                                        <Stack direction="row" spacing={2} alignItems="center">
                                                            <Typography component="h3" variant="h4">
                                                                {paymentMethod.cardBrand}
                                                            </Typography>
                                                            {subscription.paymentMethod === paymentMethod.id &&
                                                                <Chip label="active" color="success" size="small" />
                                                            }

                                                        </Stack>
                                                    } subheader={
                                                        <Grid container>
                                                            <Grid item xs>**** **** **** {paymentMethod.cardLast4}</Grid>
                                                            <Grid item>{paymentMethod.cardExpMonth} / {paymentMethod.cardExpYear}</Grid>
                                                        </Grid>
                                                    } />
                                                    <CardActions>
                                                        <Button variant="outlined" color="success" disabled={subscription.paymentMethod === paymentMethod.id || processing} onClick={() => {
                                                            setProcessing(true);
                                                            setError(null);
                                                            const updateSubscriptionPaymentMethod = httpsCallable(functionsInstance, 'updatesubscriptionpaymentmethod');
                                                            return updateSubscriptionPaymentMethod({
                                                                subscriptionId: currentGroup.subscriptionId,
                                                                paymentMethodId: paymentMethod.id
                                                            }).then(() => {
                                                                // update subscription default payment
                                                                setSubscription(prevState => ({
                                                                    ...prevState,
                                                                    paymentMethod: paymentMethod.id
                                                                }));
                                                                setProcessing(false);
                                                            }).catch(err =>{
                                                                setError(err.message);
                                                                setProcessing(false);
                                                            });
                                                        }}>Set Default</Button>
                                                        <Button variant="outlined" color="error" disabled={subscription.paymentMethod === paymentMethod.id || processing} onClick={() => {
                                                            setProcessing(true);
                                                            setError(null);
                                                            const removePaymentMethod = httpsCallable(functionsInstance, 'removepaymentmethod');
                                                            return removePaymentMethod({
                                                                paymentMethodId: paymentMethod.id
                                                            }).then(() => {
                                                                setPaymentMethods(prevState => prevState.filter(row => {
                                                                    return row.id !== paymentMethod.id
                                                                }));
                                                                setProcessing(false);
                                                            }).catch(err =>{
                                                                setError(err.message);
                                                                setProcessing(false);
                                                            });
                                                        }}>Remove</Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </>
                        )}
                    </Box>
                    </>
                )}
            </>) : (<>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Page Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </>
    )
}