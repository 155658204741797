import { Alert, List, ListItem, ListItemText, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination, Stack, TextField, IconButton, ToggleButton, ToggleButtonGroup, Snackbar } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../lib/Auth";
import { AppContext } from "../../lib/AppProvider";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, orderBy } from 'firebase/firestore';
import { getAuth, sendEmailVerification } from "firebase/auth";

import {AdminPageContext} from "./AdminPageContext";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const AdminCoupons = () => {
    const auth = getAuth();
    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(AppContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [ prompt, setPrompt ] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);

    const { admin } = useContext(AdminPageContext);

    const [ couponValue, setCouponValue ] = React.useState(null);
    const [isFreeAccess, setIsFreeAccess] = React.useState('coupon');
    const [ existingCoupons, setExistingCoupons ] = React.useState([]);
    const [ clipboardMessage, setClipboardMessage ] = React.useState('');
    const [ couponToBeDeleted, setCouponToBeDeleted ] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [loadingFreeAccessCodeButton, setLoadingFreeAccessCodeButton] = React.useState(false);

    //console.log('authUser ', authUser);
    console.log('admin', admin);

    const [open, setOpen] = React.useState(false);

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setCouponToBeDeleted(null);
        setOpenDialog(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setClipboardMessage('');
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setIsFreeAccess(newValue);
    }

    const handleCreateFreeAccessCodeButtonClick = async () => {
        setLoadingFreeAccessCodeButton(true);
        const adminCreateCouponCall = httpsCallable(functionsInstance, 'admincreatecoupon');
        adminCreateCouponCall({
            //rate: discountRate,
        }).then((res) => {
            //console.log('create coupon res', res);
            setLoadingFreeAccessCodeButton(false);
            //console.log('coupon', res.data.couponCode);
            getExistingCoupons();
        }).catch(err => {
            console.log('err', err);
            setError(err.message);
            setLoadingFreeAccessCodeButton(false);
        });
    }

    const handleCreateCouponButtonClick = async () => {
        setLoading(true);
        const intValue = parseInt(couponValue, 10);
        let discountRate = null;
        if (!isNaN(intValue) && intValue>=1 && intValue<100) {
            discountRate = intValue;
            //console.log('discountRate', discountRate);
            
            const adminCreateCouponCall = httpsCallable(functionsInstance, 'admincreatecoupon');
            adminCreateCouponCall({
                rate: discountRate,
            }).then((res) => {
                //console.log('create coupon res', res);
                setLoading(false);
                //console.log('coupon', res.data.couponCode);
                setCouponValue(null);
                getExistingCoupons();
            }).catch(err => {
                console.log('err', err);
                setError(err.message);
                setLoading(false);
            });
        } else {
            setError('Invalid discount rate.');
            setLoading(false);
        }
    }

    const copyToClipboard = (copyText) => {
        navigator.clipboard.writeText(copyText).then(() => {
                setClipboardMessage("Copied to Clipboard: " + copyText);
                setOpen(true);
        }).catch(err => {
                console.error('Failed to copy: ', err);
        });
    }

    const prepareDeleteCoupon = (couponCode) => {
        setCouponToBeDeleted(couponCode);
        handleClickOpenDialog();
    }

    const deleteCoupon = async () => {
        if (authUser.user) {
            try {
                await deleteDoc(doc(firestoreInstance, '/coupons/', couponToBeDeleted));
                getExistingCoupons();
                setError(null);
            } catch (error) {
                setCouponToBeDeleted(null);
                setError(error);
                console.error("Error deleting coupon: ", error);
            }
        }
        handleCloseDialog();
    }
    
    const getExistingCoupons = () => {
        const couponsRef = collection(firestoreInstance, '/coupons');
        const couponsQuery = query(couponsRef, orderBy("value", "desc"));
        const querySnapshot = getDocs(couponsQuery).then(snapshot => {
            if (snapshot.empty) {
                
            } else {
                const coupons = [];
                snapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    coupons.push({code:doc.id, value:doc.data().value});
                });
                setExistingCoupons(coupons);
            }
        });
    }
    useEffect(() => {
        getExistingCoupons();
    }, [auth.currentUser.uid, admin, functionsInstance, firestoreInstance]);

    return (
        <>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} 
                /*sx={{
                    '--Grid-borderWidth': '1px',
                    borderTop: 'var(--Grid-borderWidth) solid',
                    borderLeft: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    '& > div': {
                    borderRight: 'var(--Grid-borderWidth) solid',
                    borderBottom: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    },
                }}*/
            >
                <Grid size={{ xs: 12, md: 12 }}>
                    {error !== null &&
                        <Alert severity="error">{error}</Alert>
                    }
                    {prompt !== null &&
                        <Alert severity="info">
                            {prompt}
                        </Alert>
                    }
                </Grid>
                <Grid size={{ xs: 12, md: 8 }}>
                    <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                        {admin == 'super' && (
                                <>
                                <Grid container spacing={0}>
                                    <Grid xs={12} md={12}>
                                        <Box sx={{p:2}}>
                                            BCBE Website Admin Console
                                        </Box>
                                    </Grid>
                                </Grid>
                                </>
                        )}
                        {admin == 'manager' && (
                            <Box sx={{height: '70vh', p: 10, alignContent: 'center', textAlign: 'center'}}>
                                Business Co-operator Access Permission
                            </Box>
                        )}
                        {admin == '' && (
                            <>
                                Access Permission is required.
                            </>
                        )}
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }} display="flex" justifyContent="left" alignItems="center">
                    <Typography>
                    Access Level - {admin}    
                    </Typography>
                </Grid>
            </Grid>
        </Box>
        {admin == 'super' && (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} 
                    sx={{
                        '--Grid-borderWidth': '1px',
                        borderTop: 'var(--Grid-borderWidth) solid',
                        borderLeft: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        '& > div': {
                        borderRight: 'var(--Grid-borderWidth) solid',
                        borderBottom: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        },
                    }}
                >
                    <Grid size={{ xs: 12, md: 4 }}>
                        <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                            <>Existing Coupons</>
                        </Box>
                        <List>
                            {existingCoupons && existingCoupons.map(existingCoupon => (<>
                                <ListItem
                                secondaryAction={
                                    <>
                                    <IconButton edge="end" aria-label="copy" onClick={()=>{copyToClipboard(existingCoupon.code);}}>
                                    <ContentCopyIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={()=>{prepareDeleteCoupon(existingCoupon.code);}}>
                                    <DeleteIcon />
                                    </IconButton>
                                    </>
                                }
                                >
                                <ListItemText
                                    primary={existingCoupon.code}
                                    secondary={existingCoupon.value==100 ? `Free Access` : `${existingCoupon.value}% Off`}
                                    sx={{ 
                                        '& .MuiListItemText-secondary': {
                                            color: existingCoupon.value == 100 ? 'red' : 'inherit'
                                        }
                                    }}
                                />
                                </ListItem>
                                <Divider variant="middle" component="li" />
                            </>))}
                        </List>
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }}>
                        <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                            <Box sx={{p: 2}}>
                                <ToggleButtonGroup
                                color="primary"
                                value={isFreeAccess}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                                >
                                    <ToggleButton value="coupon">Coupon</ToggleButton>
                                    <ToggleButton value="free-access">Free Access</ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                            {isFreeAccess == 'free-access' ? (<>
                                <Box sx={{p: 2}}>
                                    <Typography>
                                        Create Free Access Code.
                                    </Typography>
                                    <Box sx={{textAlign: 'right'}}>
                                    <LoadingButton
                                        onClick={handleCreateFreeAccessCodeButtonClick}
                                        loading={loadingFreeAccessCodeButton}
                                        loadingPosition="start"
                                        startIcon={<PlaylistAddCircleIcon />}
                                        variant="outlined"
                                    >
                                    Create Free Access Code
                                    </LoadingButton>
                                    </Box>
                                </Box>
                            </>) : (<>
                                <Box sx={{p: 2}}>
                                    <Typography>
                                        Enter the coupon rate (1 - 99).
                                    </Typography>
                                    <Typography>
                                        For example, entering 20 will apply a 20% discount.
                                    </Typography>
                                    <TextField id="event-coupons" label="Discount Rate" variant="outlined" placeholder="1 to 99" sx={{my:2}} fullWidth value={couponValue ? couponValue : ''} onChange={(e) => {setCouponValue(e.target.value)}}/>
                                    <Box sx={{textAlign: 'right'}}>
                                    <LoadingButton
                                        onClick={handleCreateCouponButtonClick}
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<PlaylistAddCircleIcon />}
                                        variant="outlined"
                                        disabled={!couponValue>0}
                                    >
                                    Create Coupon
                                    </LoadingButton>
                                    </Box>
                                </Box>
                            </>)}
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }}>
                        <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                            <>bcbe</>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )}
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Confirm Coupon Deletion"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
            Please confirm if you would like to delete the coupon.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={deleteCoupon} autoFocus>
                Confirm
            </Button>
            </DialogActions>
        </Dialog>
        <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            message={clipboardMessage}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
        </>
    )
}
