import React from "react";

export const AppContext = React.createContext();

export const AppProvider = ({config, children}) => {
    return (
        <AppContext.Provider value={{config}}>
            {children}
        </AppContext.Provider>
    )
}