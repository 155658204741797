import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, Button, Stack, TextField, Chip, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import React, { useContext, useState, useEffect } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import {PricingPlans, BillingDetails, PaymentMethodForm} from '../payment';
import { httpsCallable } from "firebase/functions";
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PaymentIcon from '@mui/icons-material/Payment';

import {GroupPageContext} from "./GroupPageContext";

export const GroupChangePlan = () => {

    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { currentGroup } = useContext(GroupPageContext);
    //console.log('current group', currentGroup);
    const currentSubscriptionId = currentGroup.subscriptionId;

    const [ processing, setProcessing ] = useState(false);
    const [ selectedPlan, setSelectedPlan ] = useState(null);
    const [ paymentStep, setPaymentStep ] = useState(1);
    const [ showPaymentMethod, setShowPaymentMethod ] = useState(false);
    const [ billingDetails, setBillingDetails ] = useState(null);
    const [currentPlanId, setCurrentPlanId] = useState(null);
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);
    const [paymentMethodDetail, setPaymentMethodDetail] = useState(null);
    const [ useDefaultPaymentMethod, setUseDefaultPaymentMethod ] = useState('default-method');
    const [couponCode, setCouponCode] = React.useState(null);
    const [isCouponValid, setIsCouponValid] = useState(false);
    const [isFreeAccessValid, setIsFreeAccessValid] = useState(false);
    const [discount, setDiscount] = useState(null);

    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);
    const [ success, setSuccess ] = useState(false);
    const [subscription, setSubscription] = React.useState(null);

    const selectPlan = (plan) => {
        setProcessing(true);
        setError(null);
        if (plan.free || currentPaymentMethod) {
            if (plan.free) {
                // subscribe to the new plan
                const changeSubscriptionPlan = httpsCallable(functionsInstance, 'changesubscriptionplan');
                changeSubscriptionPlan({
                    paymentMethodId: currentPaymentMethod,
                    billingDetails: null,
                    planId: plan.id,
                    subscriptionId: currentSubscriptionId
                }).then(() => {
                    setCurrentPlanId(plan.id);
                    setSuccess(true);
                    setProcessing(false);
                }).catch(err => {
                    setError(err.message);
                    setProcessing(false);
                });
            } else {
                // provide promotion code
                setSelectedPlan(plan);
                setPaymentStep(2);
                setShowPaymentMethod(true);
                setProcessing(false);
            }
        } else {
            //the group is default to free plan
            // show payment method
            setSelectedPlan(plan);
            setShowPaymentMethod(true);
            setProcessing(false);
        }
    }

    const submitPlan = (paymentMethod) => {
        setProcessing(true);
        setError(null);
        console.log('paymentMethod', paymentMethod);
        const changeSubscriptionPlan = httpsCallable(functionsInstance, 'changesubscriptionplan');
        changeSubscriptionPlan({
            paymentMethodId: paymentMethod.id,
            billingDetails: billingDetails,
            planId: selectedPlan.id,
            subscriptionId: currentSubscriptionId,
            couponCode: couponCode
        }).then(() => {
            const pmRef = doc(firestoreInstance, 'users/'+authInstance.currentUser.uid+'/paymentMethods/'+paymentMethod.id);
            return setDoc(pmRef,{
                type: paymentMethod.type,
                cardBrand: paymentMethod.card.brand,
                cardExpMonth: paymentMethod.card.exp_month,
                cardExpYear: paymentMethod.card.exp_year,
                cardLast4: paymentMethod.card.last4
            },{merge:true});
        }).then(() => {
            setPrompt('Your subscription plan has been changed.');
            setProcessing(false);
        }).catch(err => {
            setError(err.message);
            setProcessing(false);
        });
    }

    const submitPlanWithCurrentPaymentMethod = () => {
        // subscribe to the new plan with current PaymentMethod
        setProcessing(true);
        setError(null);
        if (currentPaymentMethod && selectedPlan) {
            const plan = selectedPlan;
            const changeSubscriptionPlan = httpsCallable(functionsInstance, 'changesubscriptionplan');
            changeSubscriptionPlan({
                paymentMethodId: currentPaymentMethod,
                billingDetails: null,
                planId: plan.id,
                subscriptionId: currentSubscriptionId,
                couponCode: couponCode
            }).then(() => {
                setCurrentPlanId(plan.id);
                setSuccess(true);
                setProcessing(false);
            }).catch(err => {
                setError(err.message);
                setProcessing(false);
            });
        }
    }

    const handleChange = (event, newValue) => {
        setUseDefaultPaymentMethod(newValue);
    }

    const handleApplyCouponCode = () => {
        console.log('Coupon Code', couponCode);
        setIsCouponValid(false);
        setIsFreeAccessValid(false);
        
        //get coupon
        const couponRef = doc(firestoreInstance, 'coupons', couponCode);
        getDoc(couponRef).then(docSnap => {
            const couponData = docSnap.data();
            console.log('couponData', couponData);
            if (couponData.value) {
                if (couponData.value == 100) {
                    setIsFreeAccessValid(true);
                } else {
                    setIsCouponValid(true);
                    setError(null);
                    setDiscount(couponData.value);
                }
            };
        }).catch(error => {
            //console.log('error', error);
            setIsCouponValid(false);
            setIsFreeAccessValid(false);
            setError('Invalid Coupon Code.');
        });
        
    }

    useEffect(() => {
        const subRef = doc(firestoreInstance, "subscriptions", currentSubscriptionId);
        getDoc(subRef).then(docSnap => {
            const sub = docSnap.data();
            setCurrentPlanId(sub.planId);
            setCurrentPaymentMethod(sub.paymentMethod);
            //get payment mehtod details
            // load payment method of the user
            const paymentMethodRef = doc(firestoreInstance, 'users/'+auth.currentUser.uid+'/paymentMethods', sub.paymentMethod);
            getDoc(paymentMethodRef).then(snap => {
                const payment = snap.data();
                console.log('paymentMethodDetail', payment);
                setPaymentMethodDetail(payment);
            });

        }).catch(error => {
            console.log('error', error);
        });
    }, [groupSlug, currentSubscriptionId]);

    return (
        <>
            {currentGroup.isGroupCreator ? (<>
                {success &&
                    <Alert severity="success">Your subscription plan has been changed.</Alert>
                }
                <Box>{currentGroup.groupName} Plans</Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                    {showPaymentMethod ? (
                        <>
                        <Stack spacing={3}>
                            { paymentStep === 1 && 
                                <>
                                    <Typography
                                    component="h1"
                                    variant="h3"
                                    align="center"
                                    color="text.primary"
                                    gutterBottom
                                    >
                                    Your Billing Details
                                    </Typography>
                                    {error !== null && 
                                        <Alert severity="error">{error}</Alert>
                                    }
                                    <BillingDetails buttonText={"Continue"} setBillingDetailsObject={(obj) => {
                                            setBillingDetails(obj);
                                            setPaymentStep(2);
                                        }
                                    } />
                                </>
                            }
                            { paymentStep === 2 && 
                                <>
                                    <Box sx={{py:2}}>
                                        <Box>
                                        <Typography>Description</Typography>
                                        <Typography>{selectedPlan.title} Plan</Typography>
                                        <Typography>{selectedPlan.currency}{selectedPlan.price}/{selectedPlan.frequency}</Typography>
                                        <Typography>
                                            {selectedPlan.currency}{selectedPlan.price}
                                        </Typography>
                                        </Box>
                                        <Box sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}>
                                            <InputBase
                                                sx={{ ml: 1, flex: 1, borderBottom: '1px solid' }}
                                                placeholder="Have a Coupon? Enter Here."
                                                inputProps={{ 'aria-label': 'enter coupon code' }}
                                                value={couponCode}
                                                onChange={e => setCouponCode(e.target.value)}
                                            />
                                            <Button sx={{ ml: '10px' }} variant="outlined" startIcon={<ConfirmationNumberIcon />} 
                                            disabled={couponCode ? false : true}
                                            size="small"
                                            onClick={handleApplyCouponCode}>
                                                Apply
                                            </Button>
                                        </Box>
                                        <Box>
                                        {discount ? (
                                            <>
                                            <Typography>
                                            -{selectedPlan.currency}{selectedPlan.price*discount/100}
                                            </Typography>
                                            <Typography>
                                                Total {selectedPlan.currency}{selectedPlan.price - selectedPlan.price*discount/100}
                                            </Typography>
                                            </>
                                        ) : (<>
                                            {isFreeAccessValid ? (
                                                <Typography>
                                                Total {selectedPlan.currency}0
                                            </Typography>
                                            ) : (
                                                <Typography>
                                                Total {selectedPlan.currency}{selectedPlan.price}
                                                </Typography>
                                            )}
                                        </>)}
                                        </Box>
                                        <Box sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}>
                                        {error !== null &&
                                            <Alert severity="error">{error}</Alert>
                                        }
                                        {isCouponValid &&
                                            <Alert severity="success">Your {discount && discount }% discount coupon is applied! </Alert>
                                        }
                                        {isFreeAccessValid &&
                                            <Alert severity="success">Your Free Access Code is valid! </Alert>
                                        }
                                        </Box>
                                    </Box>
                                    {currentPaymentMethod && paymentMethodDetail ? (
                                        <>
                                        <Box sx={{p: 2}}>
                                            <ToggleButtonGroup
                                            color="primary"
                                            value={useDefaultPaymentMethod}
                                            exclusive
                                            onChange={handleChange}
                                            aria-label="Platform"
                                            >
                                                <ToggleButton value="default-method">Use Default Payment Method</ToggleButton>
                                                <ToggleButton value="add-method">New Payment Method</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Box>
                                        <Box sx={{p: 2}}>
                                        {useDefaultPaymentMethod == 'default-method' ? (<>
                                            <Card>
                                                <CardHeader title={
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography component="h3" variant="h4">
                                                            {paymentMethodDetail.cardBrand}
                                                        </Typography>
                                                        <Chip label="active" color="success" size="small" />

                                                    </Stack>
                                                } subheader={
                                                    <Grid container>
                                                        <Grid item xs>**** **** **** {paymentMethodDetail.cardLast4}</Grid>
                                                        <Grid item>{paymentMethodDetail.cardExpMonth} / {paymentMethodDetail.cardExpYear}</Grid>
                                                    </Grid>
                                                } />
                                            </Card>
                                            <Box sx={{ p: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                                <LoadingButton
                                                    onClick={submitPlanWithCurrentPaymentMethod}
                                                    loading={processing}
                                                    loadingPosition="start"
                                                    variant="outlined"
                                                    startIcon={<PaymentIcon />}
                                                >
                                                Subscribe
                                                </LoadingButton>
                                            </Box>
                                        </>) : (<>
                                            <Typography
                                            component="h6"
                                            variant="h5"
                                            align="center"
                                            color="text.primary"
                                            gutterBottom
                                            >
                                                Add Payment Method
                                            </Typography>
                                            {error !== null && 
                                                <Alert severity="error">{error}</Alert>
                                            }
                                            <PaymentMethodForm buttonText={"Subscribe"} setPaymentMethod={submitPlan} disabled={processing} />
                                        </>)}
                                        </Box>
                                        </>
                                    ) : (<>
                                        <Typography
                                        component="h5"
                                        variant="h4"
                                        align="center"
                                        color="text.primary"
                                        gutterBottom
                                        >
                                            {currentPaymentMethod ? 'Add' : 'Setup' } Payment Method
                                        </Typography>
                                        {error !== null && 
                                            <Alert severity="error">{error}</Alert>
                                        }
                                        <PaymentMethodForm buttonText={"Subscribe"} setPaymentMethod={submitPlan} disabled={processing} />
                                    </>)}
                                </>
                                
                            }
                        </Stack>
                        </>
                    ) : (<>
                        <Grid size={{ xs: 12, md: 12 }}>
                            <PricingPlans selectPlan={selectPlan} disabled={processing} selectedPlanId={currentPlanId} />
                        </Grid>
                    </>)}
                    </Grid>
                </Box>
            </>) : (<>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Page Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </>
    )
}