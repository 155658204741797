import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, Button, Stack, TextField } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import {PricingPlans, BillingDetails, PaymentMethodForm} from '../payment';
import { httpsCallable } from "firebase/functions";

import {GroupPageContext} from "./GroupPageContext";
import { GroupMenuContext } from './GroupMenuContext';

export const GroupMembershipChangePlan = () => {

    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const supportedPlans = config.paymentConfig.groupPlans;
    const { groupSlug } = useParams();

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { currentGroup, setCurrentGroup } = useContext(GroupPageContext);
    const { setGroup } = useContext(GroupMenuContext);
    let currentGroupSubscriptionId = currentGroup.groupSubscriptionId;
    console.log('currentGroup', currentGroup);

    const [ processing, setProcessing ] = useState(false);
    const [ selectedPlan, setSelectedPlan ] = useState(null);
    const [ paymentStep, setPaymentStep ] = useState(1);
    const [ showPaymentMethod, setShowPaymentMethod ] = useState(false);
    const [ billingDetails, setBillingDetails ] = useState(null);
    const [currentPlanId, setCurrentPlanId] = useState(null);
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);

    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);
    const [ success, setSuccess ] = useState(false);

    const selectPlan = (plan) => {
        setProcessing(true);
        setError(null);
        if (plan.free || currentPaymentMethod) {
            // subscribe to the new plan
            const changeGroupSubscriptionPlan = httpsCallable(functionsInstance, 'changegroupsubscriptionplan');
            changeGroupSubscriptionPlan({
                groupId: currentGroup.id,
                paymentMethodId: currentPaymentMethod,
                billingDetails: null,
                planId: plan.id,
                subscriptionId: currentGroupSubscriptionId
            }).then(() => {
                setCurrentGroup(prevState => ({
                    ...prevState,
                    permission: plan.id
                }));
                // update group menu
                setGroup(prevState => ({
                    ...prevState,
                    permission: plan.id
                }));
                navigate(config.pathnames.Group.replace(":groupSlug", currentGroup.slug));
            }).catch(err => {
                setError(err.message);
                setProcessing(false);
            });
        } else {
            //the group is default to free plan
            // show payment method
            setSelectedPlan(plan);
            setShowPaymentMethod(true);
            setProcessing(false);
        }
    }

    const submitPlan = (paymentMethod) => {
        setProcessing(true);
        setError(null);
        console.log('paymentMethod', paymentMethod, authInstance.currentUser.uid);
        const changeGroupSubscriptionPlan = httpsCallable(functionsInstance, 'changegroupsubscriptionplan');
        changeGroupSubscriptionPlan({
            groupId: currentGroup.id,
            paymentMethodId: paymentMethod.id,
            billingDetails: billingDetails,
            planId: selectedPlan.id,
            subscriptionId: currentGroupSubscriptionId
        }).then(() => {
            const pmRef = doc(firestoreInstance, 'users/'+authInstance.currentUser.uid+'/paymentMethods/'+paymentMethod.id);
            return setDoc(pmRef,{
                type: paymentMethod.type,
                cardBrand: paymentMethod.card.brand,
                cardExpMonth: paymentMethod.card.exp_month,
                cardExpYear: paymentMethod.card.exp_year,
                cardLast4: paymentMethod.card.last4
            },{merge:true});
        }).then(() => {
            setCurrentGroup(prevState => ({
                ...prevState,
                permission: selectedPlan.id
            }));
            // update group menu
            setGroup(prevState => ({
                ...prevState,
                permission: selectedPlan.id
            }));
            navigate(config.pathnames.Group.replace(":groupSlug", currentGroup.slug));
        }).catch(err => {
            setError(err.message);
            setProcessing(false);
        });
    }

    useEffect(() => {
        //console.log('currentGroupSubscriptionId', currentGroupSubscriptionId);
        if (currentGroupSubscriptionId) {
            const subRef = doc(firestoreInstance, "groupSubscriptions", currentGroupSubscriptionId);
            getDoc(subRef).then(docSnap => {
                const sub = docSnap.data();
                setCurrentPlanId(sub.planId);
                setCurrentPaymentMethod(sub.paymentMethod);
            }).catch(error => {
                console.log('error', error);
            });
        }
    }, [groupSlug, currentGroupSubscriptionId]);

    return (
        <>
            {currentGroup.permission && currentGroup.groupSubscriptionId ? (<>
                {success &&
                    <Alert severity="success">Your subscription plan has been changed.</Alert>
                }
                <Box>{currentGroup.groupName} Subscription Plans</Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                    {showPaymentMethod ? (
                        <>
                        <Stack spacing={3}>
                            { paymentStep === 1 && 
                                <>
                                    <Typography
                                    component="h1"
                                    variant="h3"
                                    align="center"
                                    color="text.primary"
                                    gutterBottom
                                    >
                                    Your Billing Details
                                    </Typography>
                                    {error !== null && 
                                        <Alert severity="error">{error}</Alert>
                                    }
                                    <BillingDetails buttonText={"Continue"} setBillingDetailsObject={(obj) => {
                                            setBillingDetails(obj);
                                            setPaymentStep(2);
                                        }
                                    } />
                                </>
                            }
                            { paymentStep === 2 && 
                                <>
                                    <Typography
                                    component="h1"
                                    variant="h3"
                                    align="center"
                                    color="text.primary"
                                    gutterBottom
                                    >
                                    Setup Payment Method
                                    </Typography>
                                    {error !== null && 
                                        <Alert severity="error">{error}</Alert>
                                    }
                                    <PaymentMethodForm buttonText={"Subscribe"} setPaymentMethod={submitPlan} disabled={processing} />
                                </>
                                
                            }
                        </Stack>
                        </>
                    ) : (<>
                        <Grid size={{ xs: 12, md: 12 }}>
                            <PricingPlans selectPlan={selectPlan} disabled={processing} supportedPlans={supportedPlans} selectedPlanId={currentPlanId} />
                        </Grid>
                    </>)}
                    </Grid>
                </Box>
            </>) : (<>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Page Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </>
    )
}