import React, { useContext, useEffect, useState } from "react";
import { useParams, Outlet } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import { AppContext } from "../../lib/AppProvider";
import { AuthContext } from "../../lib/Auth";
import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, styled } from "@mui/material";
import Grid from '@mui/material/Grid2';
import {BorderLinearProgress} from '../common';

export const GroupPageContext = React.createContext();

export const GroupPageProvider = ({children}) => {
    const { groupSlug } = useParams();
    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(AppContext);
    const [loading, setLoading] = React.useState(true);
    const [ currentGroup, setCurrentGroup ] = useState(null);

    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);

    const getGroupSubscription = async (groupId) => {
        let groupSub;
        const uid = authUser.user ? authUser.user.uid : null;
        if (!uid) return null;
        const groupAccessRef = collection(firestoreInstance, '/groupAccess');
        const q = query(groupAccessRef, and(where('groupId', '==', groupId), where('uid', '==', uid)));
        groupSub = await getDocs(q).then(async snapshots => {
            if (snapshots.empty) {
                return null;
            } else {
                const groupAcess = snapshots.docs[0];
                const groupAccessData = groupAcess.data();
                //console.log('groupAccessData', groupAccessData);
                const subscriptionId = groupAccessData.subscriptionId;
                const subDocRef = doc(firestoreInstance, "groupSubscriptions", subscriptionId);
                return await getDoc(subDocRef).then(docSnap => {
                    if (docSnap.exists()) {
                        const groupSubscription = docSnap.data();
                        console.log('groupSubscription', groupSubscription);
                        if (groupSubscription.subscriptionStatus == 'active' || groupSubscription.subscriptionStatus == 'trialing')
                            return {
                                    planId: groupSubscription.planId,
                                    paymentMethod: groupSubscription.paymentMethod,
                                    subscriptionId: subscriptionId,
                                    status: groupSubscription.subscriptionStatus,
                                    periodEnd: groupSubscription.subscriptionCurrentPeriodEnd
                            };
                        else return null;
                    } else {
                        return null;
                    }
                }).catch(error => {
                    return null;
                });
            }
        });

        return groupSub;
    }

    useEffect(() => {
        if ((typeof groupSlug !== 'undefined' ) && groupSlug) {
            setLoading(true);
            //console.log('page groupSlug', groupSlug);
            const groupRef = collection(firestoreInstance, '/groups');
            const q = authUser.user ? query(groupRef, and(where('slug', '==', groupSlug), or(where('public', '==', true), where('creator', '==', authUser.user.uid)))) : query(groupRef, and(where('slug', '==', groupSlug), where('public', '==', true)));
            getDocs(q).then(snapshots => {
                if (snapshots.empty) {
                    setLoading(false);
                } else {
                    snapshots.forEach(async group => {
                        //console.log('group ID', group.id, 'group data', group.data());
                        const groupSub = await getGroupSubscription(group.id);
                        if (groupSub) {
                            setCurrentGroup({
                                id:group.id, isGroupCreator: false, permission: groupSub.planId, paymentMethod: groupSub.paymentMethod, groupSubscriptionId: groupSub.subscriptionId, membershipStatus: groupSub.status, accessPeriodEnd: groupSub.periodEnd, ...group.data()
                            });
                        } else {
                            setCurrentGroup({
                                id:group.id, isGroupCreator: false, permission: null, paymentMethod: null, groupSubscriptionId: null, membershipStatus:null, accessPeriodEnd: null, ...group.data()
                            });
                        }

                        if (authUser.user && authUser.user.uid) {
                            const groupData = group.data();
                            const creatorId = groupData.creator;
                            const userDocRef = doc(firestoreInstance, "users", creatorId);
                            if (groupData.public === false && creatorId == authUser.user.uid) {
                                setPrompt('Set your group to public when you are ready.');
                            }
    
                            if (creatorId == authUser.user.uid) {
                                getDoc(userDocRef).then(docSnap => {
                                    if (docSnap.exists()) {
                                        const user = docSnap.data();
                                        //console.log('user', user);
                                        setCurrentGroup(prevState => ({
                                            ...prevState,
                                            isGroupCreator: true
                                        }));
                                        setLoading(false);
                                    } else {
                                        setLoading(false);
                                    }
                                }).catch(error => {
                                    setLoading(false);
                                });
                            } else {
                                setLoading(false);
                            }
                        } else {
                            setLoading(false);
                        }
                    });
                }
            });
        }
    }, [groupSlug, firestoreInstance]);

    return (
        <>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} >
                <Grid size={{ xs: 12, md: 12 }}>
                    {error !== null &&
                        <Alert severity="error">{error}</Alert>
                    }
                    {prompt !== null &&
                        <Alert severity="info">
                            {prompt}
                        </Alert>
                    }
                </Grid>
            </Grid>
        </Box>
        {!loading && currentGroup ? (
            <GroupPageContext.Provider value={{currentGroup, setCurrentGroup}}>
                <Outlet />
            </GroupPageContext.Provider>
        ) : (<>
            {loading ? (<BorderLinearProgress />) : (
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Group Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            )}
            </>
        )}
        </>
    )

}