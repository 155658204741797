import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, Button, Stack, TextField } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import {GroupPageContext} from "./GroupPageContext";
import { GroupMenuContext } from './GroupMenuContext';

import {PricingPlans, BillingDetails, PaymentMethodForm} from '../payment';
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import { httpsCallable } from "firebase/functions";

export const JoinGroup = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();
    const { currentGroup, setCurrentGroup } = useContext(GroupPageContext);
    const { setGroup } = useContext(GroupMenuContext);
    let currentGroupSubscriptionId = currentGroup.groupSubscriptionId;

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const [ processing, setProcessing ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);
    const [ success, setSuccess ] = useState(false);

    const supportedPlans = config.paymentConfig.groupPlans;
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);
    const [currentPlanId, setCurrentPlanId] = useState(null);
    const [ selectedPlan, setSelectedPlan ] = useState(null);
    const [ paymentStep, setPaymentStep ] = useState(1);
    const [ showPaymentMethod, setShowPaymentMethod ] = useState(false);
    const [ billingDetails, setBillingDetails ] = useState(null);

    console.log('currentGroup', currentGroup);

    const selectPlan = (plan) => {
        console.log('plan', plan);
        setProcessing(true);
        setError(null);
        if(plan.free || currentPaymentMethod){
            // subscribe to free plans on selection
            const groupSubscription = httpsCallable(functionsInstance, 'groupsubscription');
            groupSubscription({
                groupId: currentGroup.id,
                planId: plan.id,
                paymentMethodId: currentPaymentMethod,
                BillingDetails: null
            }).then((res) => {
                if(res.data && res.data.subscriptionId){
                    //console.log('res.data', res.data);
                    currentGroupSubscriptionId = res.data.subscriptionId;
                    setCurrentGroup(prevState => ({
                        ...prevState,
                        permission: plan.id, 
                        //paymentMethod: currentPaymentMethod, 
                        groupSubscriptionId: currentGroupSubscriptionId
                    }));
                    // update group menu
                    setGroup(prevState => ({
                        ...prevState,
                        permission: plan.id
                    }));
                    navigate(config.pathnames.Group.replace(":groupSlug", currentGroup.slug));
                }else{
                    setError("Failed to subscribe to group.");
                    setProcessing(false);                    
                }
            }).catch(error => {
                setError(error.message);
                setProcessing(false);
            })
        }else{
            // show payment method
            setSelectedPlan(plan);
            setShowPaymentMethod(true);
            setProcessing(false);
        }
    }

    const submitPlan = (paymentMethod) => {
        setProcessing(true);
        setError(null);
        console.log('paymentMethod', paymentMethod);
        const creategGoupSubscriptionPlan = httpsCallable(functionsInstance, 'creategroupsubscriptionplan');
        creategGoupSubscriptionPlan({
            groupId: currentGroup.id,
            paymentMethodId: paymentMethod.id,
            billingDetails: billingDetails,
            planId: selectedPlan.id,
            //subscriptionId: currentGroupSubscriptionId
        }).then((res) => {
            currentGroupSubscriptionId = res.data.subscriptionId;
            const pmRef = doc(firestoreInstance, 'users/'+authInstance.currentUser.uid+'/paymentMethods/'+paymentMethod.id);
            return setDoc(pmRef,{
                type: paymentMethod.type,
                cardBrand: paymentMethod.card.brand,
                cardExpMonth: paymentMethod.card.exp_month,
                cardExpYear: paymentMethod.card.exp_year,
                cardLast4: paymentMethod.card.last4
            },{merge:true});
        }).then(() => {
            setCurrentGroup(prevState => ({
                ...prevState,
                permission: selectedPlan.id, 
                paymentMethod: paymentMethod.id, 
                groupSubscriptionId: currentGroupSubscriptionId
            }));
            // update group menu
            setGroup(prevState => ({
                ...prevState,
                permission: selectedPlan.id
            }));
            navigate(config.pathnames.Group.replace(":groupSlug", currentGroup.slug));
        }).catch(err => {
            setError(err.message);
            setProcessing(false);
        });
    }

    useEffect(() => {
        if (currentGroupSubscriptionId) {
            const groupSubRef = doc(firestoreInstance, "groupSubscriptions", currentGroupSubscriptionId);
            getDoc(groupSubRef).then(docSnap => {
                const sub = docSnap.data();
                setCurrentPlanId(sub.planId);
                setCurrentPaymentMethod(sub.paymentMethod);
            }).catch(error => {
                console.log('error', error);
            });
        }
    }, [groupSlug, currentGroupSubscriptionId]);

    return (
        <>
        {!currentGroup.permission && !currentGroup.isGroupCreator ? (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                <Grid size={{ xs: 12, md: 8 }}>
                    <Typography>
                        Join {currentGroup.groupName}
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Typography>
                        
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                {showPaymentMethod ? (
                    <>
                    <Stack spacing={3}>
                        { paymentStep === 1 && 
                            <>
                                <Typography
                                component="h1"
                                variant="h3"
                                align="center"
                                color="text.primary"
                                gutterBottom
                                >
                                Your Billing Details
                                </Typography>
                                {error !== null && 
                                    <Alert severity="error">{error}</Alert>
                                }
                                <BillingDetails buttonText={"Continue"} setBillingDetailsObject={(obj) => {
                                        setBillingDetails(obj);
                                        setPaymentStep(2);
                                    }
                                } />
                            </>
                        }
                        { paymentStep === 2 && 
                            <>
                                <Typography
                                component="h1"
                                variant="h3"
                                align="center"
                                color="text.primary"
                                gutterBottom
                                >
                                Setup Payment Method
                                </Typography>
                                {error !== null && 
                                    <Alert severity="error">{error}</Alert>
                                }
                                <PaymentMethodForm buttonText={"Subscribe"} setPaymentMethod={submitPlan} disabled={processing} />
                            </>
                            
                        }
                    </Stack>
                    </>
                ) :(
                    <PricingPlans selectPlan={selectPlan} disabled={processing} supportedPlans={supportedPlans} selectedPlanId={currentPlanId} />
                )}
                </Grid>
                </Grid>
            </Box>
        ) : (<>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} >
                    <Grid size={{ xs: 12, md: 12 }}>
                        <Alert severity="info">
                            Page Not Found.
                        </Alert>
                    </Grid>
                </Grid>
            </Box>
        </>)}
        </>
    )
}